import { AxiosResponse } from "axios";
import { ROUTERS } from "constants/routes.constant";
import { AUTH_ENDPOINT } from "constants/endpoint.constant";
import { User } from "models/auth";
import { get, post, put } from "./base.service";

export const login = (email: string): Promise<AxiosResponse> => {
  const url = ROUTERS.LOGIN;
  return get(url, { email });
};

export const refreshTokenFirebase = (
  grantType: string,
  refreshToken: string
): Promise<AxiosResponse> => {
  const url = `${process.env.REACT_APP_REFRESH_TOKEN}?key=${refreshToken}`;
  return post(url, { grant_type: grantType, refresh_token: refreshToken });
};
export const registerUser = (user: User): Promise<AxiosResponse> => {
  const url = AUTH_ENDPOINT.USER_REGISTER;
  return post(url, user);
};
export const getUserInfo = (): Promise<AxiosResponse> => {
  const url = AUTH_ENDPOINT.USER_INFO;
  return get(url);
};

export const updateUserInfo = (user: User): Promise<AxiosResponse> => {
  const url = AUTH_ENDPOINT.USER_INFO;
  return post(url, user);
};

export const signedIn = (): Promise<AxiosResponse> => {
  const url = AUTH_ENDPOINT.SIGNED_IN;
  return put(url);
};

export const signedOut = (): Promise<AxiosResponse> => {
  const url = AUTH_ENDPOINT.SIGNED_OUT;
  return put(url);
};

export const signInEmailLink = (email: string): Promise<AxiosResponse> => {
  const url = AUTH_ENDPOINT.SIGN_IN_EMAIL_LINK;
  return post(url, { email, url: window.location.origin });
};
