import { FirmwareState } from "models/firmware";
import { AnyAction } from "redux";
import { FIRMWARE_ACTION_TYPES } from "./firmware.types";

const INITIAL_STATE = {
  firmwareList: [],
  isLoading: false,
  error: null,
  total: 0,
};

export const firmwareReducer = (
  state = INITIAL_STATE,
  action: AnyAction
): FirmwareState => {
  const { type, payload } = action;
  switch (type) {
    case FIRMWARE_ACTION_TYPES.GET_LIST_FIRMWARE_START:
      return {
        ...state,
        isLoading: true,
        firmwareList: [],
        error: null,
      };
    case FIRMWARE_ACTION_TYPES.GET_LIST_FIRMWARE_SUCCESS:
      return {
        ...state,
        isLoading: false,
        firmwareList: payload.data,
        total: payload.total,
        error: null,
      };
    case FIRMWARE_ACTION_TYPES.GET_LIST_FIRMWARE_FAILURE:
      return {
        ...state,
        isLoading: false,
        firmwareList: [],
        total: 0,
        error: payload,
      };
    default:
      return state;
  }
};
