import { all, call } from "redux-saga/effects";
import { adminSaga } from "./admin/admin.saga";
import { authSaga } from "./auth/auth.saga";
import { listDownloadSaga } from "./download/download.saga";
import { wearaSaga } from "./weara/weara.saga";
import { firmwareSaga } from "./firmware/firmware.saga";
import { serviceSaga } from "./service/service.saga";
import { userSaga } from "./user/user.saga";

export function* rootSaga() {
  yield all([
    call(authSaga),
    call(adminSaga),
    call(listDownloadSaga),
    call(wearaSaga),
    call(firmwareSaga),
    call(serviceSaga),
    call(userSaga),
  ]);
}
