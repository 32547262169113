/* eslint-disable max-params */
import axios, { AxiosResponse, Method } from "axios";
import { LOCAL_STORAGE_KEY } from "constants/common.constant";
import { clearAction, refreshTokenFalse } from "store/auth/auth.action";
import { TypeCommon } from "models/common";
import { store } from "../store/store";
import { refreshTokenFirebase } from "utils/firebase.utils";
import {
  ERROR_MESSAGE,
  RESPONSE_STATUS_CODES,
} from "constants/response.constant";
import { ROUTERS } from "constants/routes.constant";
import { checkProtectedRouter } from "utils/common.util";

const axiosInstance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  withCredentials: false,
});

axiosInstance.interceptors.request.use(
  (config: TypeCommon) => {
    let token = "";
    const currentUserToken = localStorage.getItem(
      LOCAL_STORAGE_KEY.CURRENT_USER_TOKEN
    );
    if (currentUserToken) {
      token = JSON.parse(currentUserToken).accessToken;
    }
    const headers = token
      ? {
          Authorization: `Bearer ${token}`,
          is_web: true,
        }
      : {};
    config.headers = {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...config.headers,
      ...headers,
    };
    config.counter = config.counter || 0;
    return config;
  },
  (error: Error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const config = error.config;
    const maxCallRetry = 2;
    if (
      error?.response?.data?.statusCode ===
        RESPONSE_STATUS_CODES.TOKEN_EXPIRED &&
      config.counter < maxCallRetry
    ) {
      config.counter += 1;
      const token = await refreshTokenFirebase();
      if (token) {
        localStorage.setItem(
          LOCAL_STORAGE_KEY.CURRENT_USER_TOKEN,
          JSON.stringify({ accessToken: token })
        );
        config.headers = {
          ...config.headers,
          Authorization: `Bearer ${token}`,
        };
        config.counter = 0;
      }
      return axiosInstance.request(config);
    } else if (
      error?.response?.data?.statusCode ===
        RESPONSE_STATUS_CODES.TOKEN_EXPIRED &&
      config.counter === maxCallRetry
    ) {
      localStorage.removeItem(LOCAL_STORAGE_KEY.CURRENT_USER_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_KEY.EMAIL_FOR_SIGN_IN);
      store.dispatch(refreshTokenFalse());
    }
    if (error?.response?.data?.message === ERROR_MESSAGE.UNAUTHORIZED.message) {
      localStorage.removeItem(LOCAL_STORAGE_KEY.CURRENT_USER_TOKEN);
      localStorage.removeItem(LOCAL_STORAGE_KEY.EMAIL_FOR_SIGN_IN);
      store.dispatch(clearAction());
      window.location.replace(ROUTERS.LOGIN);
    }
    if (
      config.method === "get" &&
      (error?.response?.data?.statusCode === RESPONSE_STATUS_CODES.NOT_FOUND ||
        error?.response?.data?.statusCode === RESPONSE_STATUS_CODES.BAD_REQUEST)
    ) {
      checkProtectedRouter(window.location.pathname);
    }
    return Promise.reject(error);
  }
);

export const get = (
  url: string,
  params?: TypeCommon,
  headers?: TypeCommon
): Promise<AxiosResponse> => {
  return axiosInstance.get(url, {
    headers,
    params,
  });
};
export const post = (
  url: string,
  body?: TypeCommon,
  params?: TypeCommon,
  headers?: TypeCommon
): Promise<AxiosResponse> => {
  return axiosInstance.post(url, body, {
    headers,
    params,
  });
};
export const put = (
  url: string,
  body?: TypeCommon,
  params?: TypeCommon,
  headers?: TypeCommon
): Promise<AxiosResponse> => {
  return axiosInstance.put(url, body, {
    headers,
    params,
  });
};
export const patch = (
  url: string,
  body?: TypeCommon,
  params?: TypeCommon,
  headers?: TypeCommon
): Promise<AxiosResponse> => {
  return axiosInstance.patch(url, body, {
    headers,
    params,
  });
};
export const deleteMethod = (
  url: string,
  params?: TypeCommon,
  headers?: TypeCommon
): Promise<AxiosResponse> => {
  return axiosInstance.delete(url, {
    headers,
    params,
  });
};

export const form = (
  url: string,
  method: Method = "POST",
  formData?: FormData,
  params?: TypeCommon,
  headers?: TypeCommon
): Promise<AxiosResponse> => {
  return axiosInstance.request({
    url,
    method,
    params,
    data: formData,
    headers: {
      ...headers,
      "Content-Type": "multipart/form-data",
    },
  });
};

export const uploadFile = (url: string, file: File): Promise<AxiosResponse> => {
  return axios.put(url, file, {
    headers: {
      "Content-Type": file.type,
    },
  });
};
