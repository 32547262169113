/* eslint-disable max-params */
import { NOTIFICATION_TYPE } from "constants/common.constant";
import { FilterType } from "models/common";
import { notification } from "antd";
import { SYSTEM_CONSTANT } from "constants/system.constant";
import moment from "moment-timezone";
import { Sex } from "constants/user.constant";
import { ROUTERS } from "constants/routes.constant";

export const isJsonObject = (strData: string): boolean => {
  try {
    JSON.parse(strData);
  } catch (e) {
    return false;
  }
  return true;
};

export const ToastMessage = (
  type: string,
  messTitle: string,
  description = ""
): void => {
  let typeToast = notification.info;
  switch (type) {
    case NOTIFICATION_TYPE.INFO:
      typeToast = notification.info;
      break;
    case NOTIFICATION_TYPE.ERROR:
      typeToast = notification.error;
      break;
    case NOTIFICATION_TYPE.SUCCESS:
      typeToast = notification.success;
      break;
    case NOTIFICATION_TYPE.WARNING:
      typeToast = notification.warning;
      break;
    default:
      break;
  }
  return typeToast({
    message: isJsonObject(messTitle) ? JSON.stringify(messTitle) : messTitle,
    description,
    duration: 3,
  });
};

export const getLocalDate = (
  awsDate: string,
  formatDate = SYSTEM_CONSTANT.SYSTEM_LOCAL_DATE_FORMAT
): string => {
  if (awsDate) {
    return moment(awsDate).tz("Asia/Tokyo").format(formatDate);
  }
  return "";
};
export const getLocalDateTime = (
  awsDate: string,
  formatDateTime = SYSTEM_CONSTANT.SYSTEM_LOCAL_DATE_TIME_FORMAT
): string => {
  if (awsDate) {
    return moment(awsDate).tz("Asia/Tokyo").format(formatDateTime);
  }
  return "";
};
export const getLocalDateEditable = (
  awsDate: string
): string | moment.Moment | null => {
  if (awsDate) {
    return moment(awsDate);
  }
  return null;
};
export const getLocalGender = (gender: number): string => {
  if (gender === Sex.Male) {
    return "男";
  } else if (gender === Sex.Female) {
    return "女";
  }
  return "";
};

export const handleChangePage = (
  pageSize: number,
  pageNumber: number,
  filter: FilterType,
  setFilter: (newFilter: FilterType) => void,
  search?: string
): void => {
  let newFilter = {
    ...filter,
    page: pageNumber,
    pageSize,
  };
  if (search) {
    newFilter = {
      ...newFilter,
      search,
    };
  }
  setFilter(newFilter);
};

export const convertDateToISOString = (date: string | Date): string => {
  return moment(date).toISOString();
};

export const convertDateToLocalTime = (
  date: string | Date,
  format?: string
): string => {
  return moment(date).format(format);
};

export const validateEmail = (email: string): boolean => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
  if (regex.test(email)) {
    return true;
  }
  return false;
};

export const convertTimeStampToTime = (unixTimestamp: number): string => {
  // Create a new JavaScript Date object based on the timestamp
  // multiplied by 1000 so that the argument is in milliseconds, not seconds.
  const date = new Date(unixTimestamp * 1000);
  // Hours part from the timestamp
  const hours = date.getHours();
  // Minutes part from the timestamp
  const minutes = "0" + date.getMinutes();
  // Seconds part from the timestamp
  const seconds = "0" + date.getSeconds();

  // Will display time in 10:30:23 format
  const formattedTime =
    hours + ":" + minutes.substring(-2) + ":" + seconds.substring(-2);
  return formattedTime;
};

export const checkProtectedRouter = (pathname: string): void => {
  const acceptRouter = pathname.split("/")[1];
  const routerElement = Object.values(ROUTERS).find((v) =>
    v.includes(acceptRouter)
  );
  if (routerElement) {
    window.location.replace(`${ROUTERS.NOT_FOUND}&preUrl=${routerElement}`);
  }
};
