import { NOTIFICATION_TYPE } from "constants/common.constant";
import { AuthState } from "models/auth";
import { AnyAction } from "redux";
import { ToastMessage } from "utils/common.util";
import { AUTH_ACTION_TYPES } from "./auth.types";
import i18n from "i18next";

const INITIAL_STATE = {
  accessToken: null,
  isLoading: false,
  error: null,
  email: "",
};

export const authReducer = (
  state = INITIAL_STATE,
  action: AnyAction
): AuthState => {
  const { type, payload } = action;
  switch (type) {
    case AUTH_ACTION_TYPES.SEND_SIGN_IN_LINK_EMAIL_SUCCESS:
      ToastMessage(NOTIFICATION_TYPE.SUCCESS, i18n.t("alertMessage.success"));
      return { ...state };
    case AUTH_ACTION_TYPES.EMAIL_SIGN_IN_WITH_LINK:
      return { ...state, isLoading: true };
    case AUTH_ACTION_TYPES.SIGN_IN_SUCCESS:
      return {
        ...state,
        accessToken: payload.accessToken,
        error: null,
        isLoading: false,
        email: "",
      };
    case AUTH_ACTION_TYPES.SIGN_OUT_SUCCESS:
      return {
        ...state,
        accessToken: null,
        error: null,
        userInfo: null,
        email: "",
      };
    case AUTH_ACTION_TYPES.GET_USER_INFO_SUCCESS:
      return { ...state, userInfo: payload };
    case AUTH_ACTION_TYPES.SIGN_IN_FAILED:
    case AUTH_ACTION_TYPES.SIGN_OUT_FAILED:
    case AUTH_ACTION_TYPES.TOKEN_EXPIRED_START:
      return {
        ...state,
        error: payload,
        accessToken: null,
        userInfo: null,
        isLoading: false,
        email: "",
      };
    case AUTH_ACTION_TYPES.AUTH_CLEAR:
      return {
        ...state,
        error: null,
        accessToken: null,
        userInfo: null,
        isLoading: false,
        email: "",
      };
    case AUTH_ACTION_TYPES.REFRESH_TOKEN_FALSE:
      ToastMessage(
        NOTIFICATION_TYPE.ERROR,
        i18n.t("alertMessage.sectionExpired")
      );
      return {
        ...state,
        error: null,
        accessToken: null,
        userInfo: null,
        isLoading: false,
        email: "",
      };
    default:
      return state;
  }
};
