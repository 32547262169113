import { AxiosResponse } from "axios";
import { WEARA_ENDPOINT } from "constants/endpoint.constant";
import {
  ModelOptionsEnum,
  OpenStatusOptionsEnum,
} from "constants/weara.constant";
import { AdminType } from "models/admin";
import { deleteMethod, get, post, put, form } from "./base.service";

export const getListWearaApi = async (
  page: number,
  pageSize: number
): Promise<AxiosResponse> => {
  return await get(WEARA_ENDPOINT.WEARA_CORE, { page, pageSize });
};

export const createWearaApi = async (
  body: AdminType
): Promise<AxiosResponse> => {
  return await post(WEARA_ENDPOINT.WEARA_CORE, [body]);
};

export const getDetailWearaApi = async (id: string): Promise<AxiosResponse> => {
  return await get(`${WEARA_ENDPOINT.WEARA_CORE}/${id}`);
};
export const updateWearaApi = async (
  body: AdminType
): Promise<AxiosResponse> => {
  return await put(`${WEARA_ENDPOINT.WEARA_CORE}/${body.id}`, body);
};

export const deleteWearaApi = async (id: string): Promise<AxiosResponse> => {
  return await deleteMethod(`${WEARA_ENDPOINT.WEARA_CORE}/${id}`);
};

export const importCsvApi = async (
  url: string,
  formData: FormData
): Promise<AxiosResponse> => {
  return await form(url, "POST", formData);
};

export const rebootWearaListApi = async (
  ids: number[]
): Promise<AxiosResponse> => {
  return await put(WEARA_ENDPOINT.REBOOT_LIST, { ids });
};

export const factoryResetListApi = async (
  ids: number[]
): Promise<AxiosResponse> => {
  return await put(WEARA_ENDPOINT.FACTORY_RESET_LIST, { ids });
};

export const updateWeara = async (
  ids: number[],
  model?: number | null,
  openingStatus?: number | null,
  fwUpdate?: number | null
): Promise<AxiosResponse> => {
  let params: {
    ids: number[];
    model?: number | null;
    opening_status?: number;
    fw_update?: number;
  } = {
    ids,
  };

  if (
    (typeof model === "number" && model in ModelOptionsEnum) ||
    model === null
  ) {
    params = {
      ...params,
      model,
    };
  }
  if (
    typeof openingStatus === "number" &&
    openingStatus in OpenStatusOptionsEnum
  ) {
    params = {
      ...params,
      opening_status: openingStatus,
    };
  }
  if (fwUpdate) {
    params = {
      ...params,
      fw_update: +fwUpdate,
    };
  }
  return await put(WEARA_ENDPOINT.UPDATE_WEARA, params);
};

export const deleteMultipleWeara = async (
  ids: number[]
): Promise<AxiosResponse> => {
  return await put(WEARA_ENDPOINT.WEARA_CORE_DELETE, { ids });
};
