export const USER_ACTION_TYPES = {
  GET_USER_LIST: "USER/GET_USER_LIST",
  GET_USER_LIST_SUCCESS: "USER/GET_USER_LIST_SUCCESS",
  GET_USER_LIST_FAILURE: "USER/GET_USER_LIST_FAILURE",

  GET_USER_DETAIL: "USER/GET_USER_DETAIL",
  GET_USER_DETAIL_SUCCESS: "USER/GET_USER_DETAIL_SUCCESS",
  GET_USER_DETAIL_FAILURE: "USER/GET_USER_DETAIL_FAILURE",

  GET_ALARM_LIST: "USER/GET_ALARM_LIST",
  GET_ALARM_LIST_SUCCESS: "USER/GET_ALARM_LIST_SUCCESS",
  GET_ALARM_LIST_FAILURE: "USER/GET_ALARM_LIST_FAILURE",

  GET_ALARM_DETAIL: "USER/GET_ALARM_DETAIL",
  GET_ALARM_DETAIL_SUCCESS: "USER/GET_ALARM_DETAIL_SUCCESS",
  GET_ALARM_DETAIL_FAILURE: "USER/GET_ALARM_DETAIL_FAILURE",

  GET_SMART_ALARM_LIST: "USER/GET_SMART_ALARM_LIST",
  GET_SMART_ALARM_LIST_SUCCESS: "USER/GET_SMART_ALARM_LIST_SUCCESS",
  GET_SMART_ALARM_LIST_FAILURE: "USER/GET_SMART_ALARM_LIST_FAILURE",

  GET_SMART_ALARM_DETAIL: "USER/GET_SMART_ALARM_DETAIL",
  GET_SMART_ALARM_DETAIL_SUCCESS: "USER/GET_SMART_ALARM_DETAIL_SUCCESS",
  GET_SMART_ALARM_DETAIL_FAILURE: "USER/GET_SMART_ALARM_DETAIL_FAILURE",

  SET_LOADING: "USER/SET_LOADING",
  GET_SELECTED_DAYS: "USER/GET_SELECTED_DAYS",
};
