import { LOCAL_STORAGE_KEY } from "./../../constants/common.constant";
import {
  getUserInfoSuccess,
  sendSignInLinkToEmailSuccess,
  signInFailed,
  signInSuccess,
  signOutFailed,
  signOutSuccess,
} from "./auth.action";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { AUTH_ACTION_TYPES } from "./auth.types";
import { ActionProps, ResponseGenerator } from "models/common";
import {
  signInWithEmailLinkFirebase,
  signOutUserFirebase,
} from "utils/firebase.utils";
import {
  signedIn,
  getUserInfo,
  signedOut,
  signInEmailLink,
} from "services/auth.service";
import {
  ERROR_MESSAGE,
  RESPONSE_STATUS_CODES,
} from "constants/response.constant";

export function* signInWithEmail({ payload: { email } }: ActionProps) {
  try {
    const { data }: ResponseGenerator = yield call(signInEmailLink, email);
    if (data && data.statusCode === RESPONSE_STATUS_CODES.GET) {
      window.localStorage.setItem(LOCAL_STORAGE_KEY.EMAIL_FOR_SIGN_IN, email);
      yield put(sendSignInLinkToEmailSuccess());
    }
  } catch (error) {
    yield put(signInFailed(error as Error));
  }
}
export function* signInWithEmailLink({ payload }: ActionProps) {
  try {
    const { user } = yield call(signInWithEmailLinkFirebase, payload);
    yield localStorage.setItem(LOCAL_STORAGE_KEY.EMAIL_FOR_SIGN_IN, payload);
    if (user) {
      yield localStorage.setItem(
        LOCAL_STORAGE_KEY.CURRENT_USER_TOKEN,
        JSON.stringify({
          accessToken: user.accessToken,
          uid: user.uid,
        })
      );
      const userInfo: ResponseGenerator = yield call(getUserInfo);
      if (
        userInfo &&
        userInfo.data &&
        userInfo.data.data &&
        userInfo.data.data.is_weara_admin
      ) {
        yield put(signInSuccess(user));
        yield put(getUserInfoSuccess(userInfo.data.data));
        yield call(signedIn);
      } else {
        yield put(
          signInFailed({
            ...ERROR_MESSAGE.UNAUTHORIZED,
            name: "UNAUTHORIZED",
          } as Error)
        );
        yield localStorage.removeItem(LOCAL_STORAGE_KEY.CURRENT_USER_TOKEN);
      }
    }
  } catch (error) {
    yield put(signInFailed(error as Error));
    yield localStorage.removeItem(LOCAL_STORAGE_KEY.CURRENT_USER_TOKEN);
  }
}
export function* signOut() {
  try {
    yield call(signOutUserFirebase);
    const result: ResponseGenerator = yield call(signedOut);
    if (result && result.data && result.data.data) {
      yield put(signOutSuccess());
      yield localStorage.removeItem(LOCAL_STORAGE_KEY.CURRENT_USER_TOKEN);
      yield localStorage.removeItem(LOCAL_STORAGE_KEY.EMAIL_FOR_SIGN_IN);
    }
  } catch (error) {
    yield put(signOutFailed(error as Error));
    // TODO: Will delete after BE handler token 30 days
    yield put(signOutSuccess());
    yield localStorage.removeItem(LOCAL_STORAGE_KEY.CURRENT_USER_TOKEN);
  }
}

export function* onEmailSignInStart() {
  yield takeLatest(AUTH_ACTION_TYPES.EMAIL_SIGN_IN_START, signInWithEmail);
}

export function* onSignInWithEmailLinkStart() {
  yield takeLatest(
    AUTH_ACTION_TYPES.EMAIL_SIGN_IN_WITH_LINK,
    signInWithEmailLink
  );
}

export function* onSignOutStart() {
  yield takeLatest(AUTH_ACTION_TYPES.SIGN_OUT_START, signOut);
}

export function* authSaga() {
  yield all([
    call(onEmailSignInStart),
    call(onSignInWithEmailLinkStart),
    call(onSignOutStart),
  ]);
}
