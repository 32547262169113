import { AxiosResponse } from "axios";
import { DOWNLOAD_ENDPOINT } from "constants/endpoint.constant";
import { PaginationType } from "models/common";
import { PayLoadDownloadType } from "models/download";
import { get, post } from "./base.service";

const urlListUser = DOWNLOAD_ENDPOINT.USER;
const urlDownload = DOWNLOAD_ENDPOINT.DOWNLOAD;
export const getListDownloadUserApi = async (
  pagination: PaginationType
): Promise<AxiosResponse> => {
  return await get(urlListUser, { ...pagination });
};
export const postDownloadUserApi = async (
  payload: PayLoadDownloadType
): Promise<AxiosResponse> => {
  return await post(urlDownload, payload);
};
