import { TypeCommon } from "models/common";
import { AnyAction } from "redux";
import { WEARA_ACTION_TYPES } from "./weara.types";

const INITIAL_STATE = {
  wearaDetail: {},
  wearaList: [],
  error: null,
  isLoading: false,
  nextFunc: false,
};

const wearaListReducer = (
  state: TypeCommon,
  type: TypeCommon,
  payload: TypeCommon
): TypeCommon => {
  switch (type) {
    case WEARA_ACTION_TYPES.GET_LIST_WEARA:
      return {
        ...state,
        isLoading: true,
      };

    case WEARA_ACTION_TYPES.GET_LIST_WEARA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wearaList: payload.data,
        wearaDetail: {},
        total: payload.total,
      };

    case WEARA_ACTION_TYPES.GET_LIST_WEARA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    default:
      return {
        ...state,
        nextFunc: true,
      };
  }
};

const wearaDetailReducer = (
  state: TypeCommon,
  type: TypeCommon,
  payload: TypeCommon
): TypeCommon => {
  switch (type) {
    case WEARA_ACTION_TYPES.GET_WEARA_DETAIL:
      return {
        ...state,
        isLoading: true,
      };

    case WEARA_ACTION_TYPES.GET_WEARA_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wearaDetail: payload,
      };

    case WEARA_ACTION_TYPES.GET_WEARA_DETAIL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: payload,
      };
    default:
      return {
        ...state,
        nextFunc: true,
      };
  }
};

const wearaCreateReducer = (
  state: TypeCommon,
  type: TypeCommon,
  payload: TypeCommon
): TypeCommon => {
  switch (type) {
    case WEARA_ACTION_TYPES.CREATE_WEARA:
      return {
        ...state,
        isLoading: true,
      };

    case WEARA_ACTION_TYPES.CREATE_WEARA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        wearaCreated: {
          data: payload,
        },
      };

    case WEARA_ACTION_TYPES.CREATE_WEARA_FAILURE:
      return {
        ...state,
        isLoading: false,
        wearaCreated: {
          error: payload?.response?.data,
        },
      };

    default:
      return {
        ...state,
        nextFunc: true,
      };
  }
};

const wearaClearReducer = (state: TypeCommon, type: TypeCommon): TypeCommon => {
  switch (type) {
    case WEARA_ACTION_TYPES.CLEAR:
      return { ...INITIAL_STATE, nextFunc: false };

    default:
      return { ...state, nextFunc: false };
  }
};

export const wearaReducer = (
  state = INITIAL_STATE,
  action: AnyAction
): TypeCommon => {
  const { type, payload } = action;
  let data;
  data = wearaListReducer(state, type, payload);
  if (data.nextFunc) {
    data = wearaDetailReducer(state, type, payload);
  }
  if (data.nextFunc) {
    data = wearaCreateReducer(state, type, payload);
  }
  if (data.nextFunc) {
    data = wearaClearReducer(state, type);
  }
  return data;
};
