export const WEARA_ACTION_TYPES = {
  GET_LIST_WEARA: "WEARA/GET_LIST_WEARA",
  GET_LIST_WEARA_SUCCESS: "WEARA/GET_LIST_WEARA_SUCCESS",
  GET_LIST_WEARA_FAILURE: "WEARA/GET_LIST_WEARA_FAILURE",

  CREATE_WEARA: "WEARA/CREATE_WEARA",
  CREATE_WEARA_SUCCESS: "WEARA/CREATE_WEARA_SUCCESS",
  CREATE_WEARA_FAILURE: "WEARA/CREATE_WEARA_FAILURE",

  GET_WEARA_DETAIL: "WEARA/GET_WEARA_DETAIL",
  GET_WEARA_DETAIL_SUCCESS: "WEARA/GET_WEARA_DETAIL_SUCCESS",
  GET_WEARA_DETAIL_FAILURE: "WEARA/GET_WEARA_DETAIL_FAILURE",

  SET_LOADING: "WEARA/SET_LOADING",
  CLEAR: "WEARA/CLEAR",
};
