export const SERVICE_ACTION_TYPES = {
  UPDATE_REQUEST: "SERVICE_SETTINGS/UPDATE_REQUEST",
  UPDATE_SUCCESS: "SERVICE_SETTINGS/UPDATE_SUCCESS",
  UPDATE_FAILED: "SERVICE_SETTINGS/UPDATE_FAILED",

  GET_REQUEST: "SERVICE_SETTINGS/GET_REQUEST",
  GET_FAILURE: "SERVICE_SETTINGS/GET_FAILURE",
  GET_SUCCESS: "SERVICE_SETTINGS/GET_SUCCESS",
  SET_LOADING: "SERVICE_SETTINGS/SET_LOADING",
};
