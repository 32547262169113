import { AxiosResponse } from "axios";
import {
  ALARM_ENDPOINT,
  SMART_ALARM_ENDPOINT,
  USER_ENDPOINT,
} from "constants/endpoint.constant";
import { UserAlarmResponse, UserSmartAlarmResponse } from "models/user";
import { deleteMethod, get, post, put } from "./base.service";

const alarms = ALARM_ENDPOINT;
const smartAlarms = SMART_ALARM_ENDPOINT;
const user = USER_ENDPOINT;

export const getUserAlarmListApi = async (
  userId: string
): Promise<AxiosResponse> => {
  return await get(`${user}/${userId}/${alarms}`);
};
export const getAlarmDetailApi = async (
  alarmId: string
): Promise<AxiosResponse> => {
  return await get(`/${alarms}/${alarmId}`);
};
export const updateUserAlarmApi = async (
  alarmId: number,
  body: UserAlarmResponse
): Promise<AxiosResponse> => {
  return await put(`/${alarms}/${alarmId}`, body);
};
export const registAlarmApi = async (
  id: string,
  body: UserAlarmResponse
): Promise<AxiosResponse> => {
  return await post(`${user}/${id}/${alarms}`, body);
};
export const deleteAlarmApi = async (
  alarmId: string
): Promise<AxiosResponse> => {
  return await deleteMethod(`/${alarms}/${alarmId}`);
};

export const getUserSmartAlarmListApi = async (
  userId: string
): Promise<AxiosResponse> => {
  return await get(`${user}/${userId}/${smartAlarms}`);
};
export const getUserSmartAlarmDetailApi = async (
  smartId: string
): Promise<AxiosResponse> => {
  return await get(`/${smartAlarms}/${smartId}`);
};
export const updateSmartAlarmApi = async (
  smartId: string,
  body: UserSmartAlarmResponse
): Promise<AxiosResponse> => {
  return await put(`/${smartAlarms}/${smartId}`, body);
};
export const deleteSmartAlarmApi = async (
  smartId: string
): Promise<AxiosResponse> => {
  return await deleteMethod(`/${smartAlarms}/${smartId}`);
};
export const registerSmartAlarmApi = async (
  id: string,
  body: UserSmartAlarmResponse
): Promise<AxiosResponse> => {
  return await post(`${user}/${id}/${smartAlarms}`, body);
};
