import { User } from "models/auth";
import { ActionProps } from "models/common";
import { createAction } from "utils/reducer.utils";
import { AUTH_ACTION_TYPES } from "./auth.types";

export const setCurrentUserLogin = (user: User): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.SET_CURRENT_USER, user);
};

export const emailSignInStart = (email: string): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.EMAIL_SIGN_IN_START, {
    email,
  });
};

export const emailSignInStartWithLink = (email: string): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.EMAIL_SIGN_IN_WITH_LINK, email);
};

export const signInSuccess = (user: User): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.SIGN_IN_SUCCESS, user);
};

export const signInFailed = (error: Error): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.SIGN_IN_FAILED, error);
};

export const signOutStart = (): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.SIGN_OUT_START);
};

export const signOutSuccess = (): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.SIGN_OUT_SUCCESS);
};

export const signOutFailed = (error: Error): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.SIGN_OUT_FAILED, error);
};

export const getUserInfoSuccess = (user: User): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.GET_USER_INFO_SUCCESS, user);
};

export const clearAction = (): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.AUTH_CLEAR);
};
export const sendSignInLinkToEmailSuccess = (): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.SEND_SIGN_IN_LINK_EMAIL_SUCCESS);
};

export const refreshTokenFalse = (): ActionProps => {
  return createAction(AUTH_ACTION_TYPES.REFRESH_TOKEN_FALSE);
};
