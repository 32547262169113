import { AdminType } from "models/admin";
import {
  ActionProps,
  FilterType,
  ListResponse,
  TypeCommon,
} from "models/common";
import { createAction } from "utils/reducer.utils";
import { ADMIN_ACTION_TYPES } from "./admin.types";

export const getDetailAdminSuccess = (admin: AdminType): ActionProps => {
  return createAction(ADMIN_ACTION_TYPES.GET_DETAIL_ADMIN_SUCCESS, admin);
};
export const getDetailAdminFailure = (): ActionProps => {
  return createAction(ADMIN_ACTION_TYPES.GET_DETAIL_ADMIN_FAILURE);
};
export const getDetailAdmin = (payload: string): ActionProps => {
  return createAction(ADMIN_ACTION_TYPES.GET_DETAIL_ADMIN, payload);
};

// list admin
export const setListAdmin = (admins: ListResponse<AdminType>): ActionProps => {
  return createAction(ADMIN_ACTION_TYPES.SET_LIST_ADMIN, admins);
};
export const getListAdminAction = (payload: FilterType): ActionProps => {
  return createAction(ADMIN_ACTION_TYPES.GET_LIST_ADMIN, payload);
};
export const getListAdminFailure = (payload: TypeCommon): ActionProps => {
  return createAction(ADMIN_ACTION_TYPES.GET_LIST_ADMIN_FAILURE, payload);
};

// update admin
export const updateAdminSuccessAction = (payload: AdminType): ActionProps => {
  return createAction(ADMIN_ACTION_TYPES.UPDATE_ADMIN_SUCCESS, payload);
};

// admin registration
export const adminRegistrationSuccessAction = (): ActionProps => {
  return createAction(ADMIN_ACTION_TYPES.ADMIN_REGISTRATION_SUCCESS);
};

// delete
export const deleteAdminSuccessAction = (): ActionProps => {
  return createAction(ADMIN_ACTION_TYPES.DELETE_ADMIN_SUCCESS);
};

export const setLoadingAction = (payload: boolean): ActionProps => {
  return createAction(ADMIN_ACTION_TYPES.SET_LOADING, payload);
};
