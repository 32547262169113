import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import translationEN from "translations/en.json";
import translationJP from "translations/jp.json";
import { LOCALE } from "./constants/common.constant";

const resources = {
  en: {
    translation: translationEN,
  },
  jp: {
    translation: translationJP,
  },
};

i18n.use(initReactI18next).init({
  resources,
  fallbackLng: LOCALE.JAPANESE,
  keySeparator: ".",
  interpolation: {
    escapeValue: false,
  },
});

export default i18n;
