import { DAYS_OF_WEEK, CONVERT_KM_TO_M } from "constants/user.constant";
import {
  AccountType,
  RUNNING_GOAL_TYPE_OPTIONS,
  SignInType,
  USER_ON_OFF_OPTIONS,
} from "constants/user.constant";
import { TFunction } from "react-i18next";
import {
  Core1FormData,
  Core2FormData,
  Core3FormData,
  RelateService,
  UserDetailResponse,
  UserMoveAlertResponse,
  WeareaCore1Request,
  WeareaCore2Request,
  WeareaCore3Request,
} from "models/user";

export const getLocalAccType = (accType: number): string => {
  switch (accType) {
    case AccountType.WearaUser:
      return "common.accountType.user";
    case AccountType.WearaAdmin:
      return "common.accountType.admin";
    case AccountType.GroupWeara:
      return "common.accountType.group";
    case AccountType.GroupAdmin:
      return "common.accountType.groupAdmin";
    default:
      return "";
  }
};

export const getLocalSignInType = (signInType: number): string => {
  switch (signInType) {
    case SignInType.EmailLink:
      return "common.signInType.email";
    case SignInType.Google:
      return "common.signInType.google";
    case SignInType.Twitter:
      return "common.signInType.twitter";
    case SignInType.Facebook:
      return "common.signInType.facebook";
    case SignInType.Apple:
      return "common.signInType.apple";
    default:
      return "";
  }
};

export const getSmartAlarmState = (isActive?: boolean): string => {
  if (isActive === true) {
    return "common.booleanState.true";
  } else if (isActive === false) {
    return "common.booleanState.false";
  }
  return "";
};

export const getLocalDaysOfWeek = (days: number): string[] => {
  let binaryDays = Number(days).toString(2);

  const MaxDaysOfWeek = 7;
  if (binaryDays.length > 0 && binaryDays.length < MaxDaysOfWeek) {
    binaryDays = binaryDays.padStart(MaxDaysOfWeek, "0");
  }

  const result = [];
  for (let index = 0; index < MaxDaysOfWeek; index += 1) {
    const dayStatus = binaryDays.charAt(index);
    if (dayStatus === "1") {
      result.push(DAYS_OF_WEEK[index]);
    }
  }
  return result;
};

const stringReplaceAt = (
  text: string,
  index: number,
  replacement: string
): string => {
  return (
    text.substring(0, index) +
    replacement +
    text.substring(index + replacement.length)
  );
};
export const getBinaryDaysOfWeek = (days: string[]): number => {
  let result = "0000000";
  for (const day of days) {
    const index = DAYS_OF_WEEK.indexOf(day);
    if (index > -1) {
      result = stringReplaceAt(result, index, "1");
    }
  }
  return parseInt(result, 2);
};
export const getOpenState = (state?: boolean): string => {
  if (state === true) {
    return "common.booleanState.true";
  } else if (state === false) {
    return "common.booleanState.false";
  }
  return "";
};

export const formatDataWearaCore1FromResponse = (
  userDetail: UserDetailResponse,
  moveAlert: UserMoveAlertResponse
): Core1FormData => {
  return {
    // Alert
    isActive: moveAlert?.is_active,
    days: getLocalDaysOfWeek(moveAlert?.weekdays),
    startTime: moveAlert?.start_time ? moveAlert?.start_time : "",
    endTime: moveAlert?.end_time ? moveAlert?.end_time : "",
    // Run Goal
    runningGoal:
      userDetail?.core_settings?.weara_core_settings
        ?.enabled_running_goal_notification,
    runningGoalType:
      userDetail?.core_settings?.weara_core_settings?.run_goal_type,
    runningcalories:
      userDetail?.core_settings?.weara_core_settings?.run_goal_calorie_value,
    runningDistance:
      userDetail?.core_settings?.weara_core_settings?.run_goal_distance_value /
      CONVERT_KM_TO_M,
    runningTimeGoal:
      userDetail?.core_settings?.weara_core_settings?.run_goal_time_value,
    // Goal
    achievement:
      userDetail?.core_settings?.weara_core_settings?.enabled_goal_notification,
    caloriesGoal: userDetail?.goals?.calorie,
    goalType: userDetail?.core_settings?.weara_core_settings?.goal_led_type,
    movementDistanceTarget: userDetail?.goals?.distance / CONVERT_KM_TO_M,
    stepGoal: userDetail?.goals?.step,
    // Setting
    liftArms:
      userDetail?.core_settings?.weara_core_settings?.enabled_detect_arm_lift,
    heartRateDetection:
      userDetail?.core_settings?.weara_core_settings
        ?.enabled_outlier_notification,
    heartRateUpperLimit:
      userDetail?.core_settings?.weara_core_settings?.max_outlier,
    heartRateLowerLimit:
      userDetail?.core_settings?.weara_core_settings?.min_outlier,
    // SOS
    sosSetting: userDetail?.sos?.is_active,
    sosNotif: userDetail?.sos?.notice_type,
    sosPhoneNumber: userDetail?.sos?.tel,
    // Other
    fallDetectNoti: userDetail?.detect_fall_down?.notice_type,
    fallDetectPhoneNumber: userDetail?.detect_fall_down?.tel,
    fallDetectSetting: userDetail?.detect_fall_down?.is_active,
    outServiceNotification:
      userDetail?.core_settings?.weara_core_settings
        ?.enabled_out_of_service_notification,
  };
};
export const formatDataWearaCore2FromResponse = (
  userDetail: UserDetailResponse
): Core2FormData => {
  return {
    threeAxis: userDetail?.frequencies?.measurements_3axis_acceleration,
    hrPpg: userDetail?.frequencies?.measurements_hr_ppg,
    temprature: userDetail?.frequencies?.measurements_temprature,
    pressure: userDetail?.frequencies?.measurements_pressure,
    calculCalories: userDetail?.frequencies?.measurements_calorie,
    calculStep: userDetail?.frequencies?.measurements_step,
    calculDistance: userDetail?.frequencies?.measurements_distance,
    calculStair: userDetail?.frequencies?.measurements_stair,
    calculNormalHr: userDetail?.frequencies?.measurements_hr_normal,
    calculExerciseHr: userDetail?.frequencies?.measurements_hr_running,
    calculSleepHr: userDetail?.frequencies?.measurements_hr_sleep,
    calculSpo2Sleep: userDetail?.frequencies?.measurements_spo2_sleep,
    calculSpo2Resting: userDetail?.frequencies?.measurements_spo2_normal,
    calculSleepBodyTemp:
      userDetail?.frequencies?.measurements_body_temperature_sleep,
    calculRestBodyTemp: userDetail?.frequencies?.measurements_body_temperature,
    calculRestHRV: userDetail?.frequencies?.measurements_hrv,
    calculSleepHRV: userDetail?.frequencies?.measurements_hrv_sleep,
    calculBarometicPressure:
      userDetail?.frequencies?.measurements_barometric_pressure,
    is3Axis: userDetail?.frequencies?.is_measurements_3axis_acceleration,
    isHrPpg: userDetail?.frequencies?.is_measurements_hr_ppg,
    isTemprature: userDetail?.frequencies?.is_measurements_temprature,
    isPressure: userDetail?.frequencies?.is_measurements_pressure,
  };
};
export const formatDataWearaCore3FromResponse = (
  userDetail: UserDetailResponse
): Core3FormData => {
  return {
    nfcTagValue: userDetail?.core_settings?.weara_core_settings?.nfc,
    diviceName: userDetail?.core_settings?.weara_core_settings?.device_name,
    topTouchSensitive:
      userDetail?.core_settings?.weara_core_settings?.top_touch_sensor,
    sideTouchSensitive:
      userDetail?.core_settings?.weara_core_settings?.side_push_sensor,
    // Convert from seconds to minutes
    communicationFrequency: userDetail?.frequencies?.send_regular_communucation
      ? Number(userDetail?.frequencies?.send_regular_communucation) / 60
      : userDetail?.frequencies?.send_regular_communucation,
  };
};

export const formatDataWearaCore1 = (
  wearaCore: Core1FormData,
  userDetail: UserDetailResponse
): WeareaCore1Request => {
  const data = {
    move_alert: {
      is_active: wearaCore?.isActive,
      end_time: wearaCore?.endTime ? wearaCore?.endTime : null,
      start_time: wearaCore?.startTime ? wearaCore?.startTime : null,
      weekdays: getBinaryDaysOfWeek(wearaCore?.days),
    },
    goals: {
      calorie: wearaCore?.caloriesGoal,
      distance: wearaCore?.movementDistanceTarget * CONVERT_KM_TO_M,
      step: wearaCore?.stepGoal,
      weight: userDetail?.goals?.weight || 0,
      sleep: userDetail?.goals?.sleep,
    },
    sos: {
      is_active: wearaCore?.sosSetting,
      notice_type: wearaCore?.sosNotif,
      tel: wearaCore?.sosPhoneNumber
        ? wearaCore?.sosPhoneNumber.toString()
        : "",
    },
    detect_fall_down: {
      is_active: wearaCore?.fallDetectSetting,
      notice_type: wearaCore?.fallDetectNoti || 0,
      tel: wearaCore?.fallDetectPhoneNumber,
    },

    core_settings: {
      weara_core_settings: {
        enabled_detect_arm_lift: wearaCore?.liftArms,
        enabled_running_goal_notification: wearaCore?.runningGoal,
        enabled_out_of_service_notification: wearaCore?.outServiceNotification,
        enabled_outlier_notification: wearaCore?.heartRateDetection,
        max_outlier: wearaCore?.heartRateUpperLimit,
        min_outlier: wearaCore?.heartRateLowerLimit,
        run_goal_calorie_value: wearaCore?.runningcalories,
        run_goal_distance_value: wearaCore?.runningDistance * CONVERT_KM_TO_M,
        run_goal_time_value: wearaCore?.runningTimeGoal,
        goal_led_type: wearaCore?.goalType,
        run_goal_type: wearaCore?.runningGoalType,
        enabled_goal_notification: wearaCore?.achievement,
      },
    },
  };
  return data;
};

export const formatDataWearaCore2 = (
  wearaCore: Core2FormData
): WeareaCore2Request => {
  const data = {
    frequencies: {
      measurements_3axis_acceleration: wearaCore?.threeAxis,
      measurements_hr_ppg: wearaCore?.hrPpg,
      measurements_temprature: wearaCore?.temprature,
      measurements_pressure: +wearaCore?.pressure,
      measurements_calorie: wearaCore?.calculCalories,
      measurements_step: wearaCore?.calculStep,
      measurements_distance: wearaCore?.calculDistance,
      measurements_stair: wearaCore?.calculStair,
      measurements_hr_normal: wearaCore?.calculNormalHr,
      measurements_hr_running: wearaCore?.calculExerciseHr,
      measurements_hr_sleep: wearaCore?.calculSleepHr,
      measurements_spo2_sleep: wearaCore?.calculSpo2Sleep,
      measurements_spo2_normal: wearaCore?.calculSpo2Resting,
      measurements_body_temperature_sleep: wearaCore?.calculSleepBodyTemp,
      measurements_body_temperature: wearaCore?.calculRestBodyTemp,
      measurements_hrv: wearaCore?.calculRestHRV,
      measurements_hrv_sleep: wearaCore?.calculSleepHRV,
      measurements_barometric_pressure: wearaCore?.calculBarometicPressure,
      is_measurements_3axis_acceleration: wearaCore?.is3Axis,
      is_measurements_hr_ppg: wearaCore?.isHrPpg,
      is_measurements_temprature: wearaCore?.isTemprature,
      is_measurements_pressure: wearaCore?.isPressure,
    },
  };
  return data;
};
export const formatDataWearaCore3 = (
  wearaCore: Core3FormData
): WeareaCore3Request => {
  const data = {
    core_settings: {
      weara_core_settings: {
        nfc: wearaCore?.nfcTagValue,
        device_name: wearaCore?.diviceName,
        top_touch_sensor: wearaCore?.topTouchSensitive,
        side_push_sensor: wearaCore?.sideTouchSensitive,
      },
    },
    // Convert from minutes to seconds
    frequencies: {
      send_regular_communucation:
        Number(wearaCore?.communicationFrequency) * 60,
    },
  };
  return data;
};
export const INITIAL_WEARA_CORE_1 = {
  isActive: USER_ON_OFF_OPTIONS[0].value,
  startTime: "08:00",
  endTime: "21:00",
  runningGoal: true,
  runningGoalType: RUNNING_GOAL_TYPE_OPTIONS[0],
  runningcalories: 300,
  runningDistance: 5,
  runningTimeGoal: 30,
  achievement: true,
  caloriesGoal: 300,
  stepGoal: 8000,
  movementDistanceTarget: 5,
  liftArms: true,
  heartRateDetection: true,
  heartRateUpperLimit: 120,
  heartRateLowerLimit: 50,
  sosSetting: false,
  fallDetectSetting: false,
  outServiceNotification: true,
};

export const INITIAL_WEARA_CORE_2 = {
  is3Axis: false,
  isHrPpg: true,
  isTemprature: false,
  isPressure: false,
  calculCaloris: 60,
  calculStep: 60,
  calculDistance: 60,
  calculStair: 60,
  calculNormalHr: 600,
  calculExerciseHr: 60,
  calculSleepHr: 60,
  calculSpo2Resting: 600,
  calculSpo2Sleep: 60,
  calculRestBodyTemp: 600,
  calculRestHRV: 60,
  calculSleepHRV: 60,
  calculBarometicPressure: 60,
  axisRawData: false,
  ppgSensorRawData: true,
  thermistorSensorRawData: false,
  tempSensorRawData: false,
};

export const INITIAL_WEARA_CORE_3 = {
  topTouchSensitive: 50,
  sideTouchSensitive: 50,
  communicationFrequency: 10,
};

export const calculateStartTime = (wakeUpTime: string): string => {
  // 30 minutes
  const splitTime = wakeUpTime.split(":");
  if (Number(splitTime[0]) > 10 && Number(splitTime[1]) < 30) {
    return `${Number(splitTime[0]) - 1}:${60 - (30 - Number(splitTime[1]))}`;
  }
  if (
    Number(splitTime[0]) <= 10 &&
    Number(splitTime[0]) > 0 &&
    Number(splitTime[1]) < 30
  ) {
    return `0${Number(splitTime[0]) - 1}:${60 - (30 - Number(splitTime[1]))}`;
  }
  if (Number(splitTime[0]) === 0 && Number(splitTime[1]) < 30) {
    return `23:${60 - (30 - Number(splitTime[1]))}`;
  }
  if (Number(splitTime[0]) <= 10 && Number(splitTime[1]) === 30) {
    return `0${Number(splitTime[0])}:00`;
  }
  if (Number(splitTime[0]) > 10 && Number(splitTime[1]) === 30) {
    return `${Number(splitTime[0])}:00`;
  }
  return `${splitTime[0]}:${Number(splitTime[1]) - 30}`;
};

export const generateValue = (
  key: keyof RelateService,
  relateService: RelateService,
  t: TFunction<"translation", undefined>
): string => {
  if (
    key &&
    relateService &&
    typeof relateService[key] !== "undefined" &&
    relateService[key] !== null
  ) {
    if (typeof relateService[key] === "boolean") {
      if (
        (key === "pauseMode" || key === "sleepStatus") &&
        relateService[key] === true
      ) {
        return t("common.booleanState.true");
      }
      if (
        (key === "pauseMode" || key === "sleepStatus") &&
        relateService[key] === false
      ) {
        return t("common.booleanState.false");
      }
      if (
        key !== "pauseMode" &&
        key !== "sleepStatus" &&
        relateService[key] === false
      ) {
        return t("common.no");
      }
      return t("common.yes");
    }
    return `${relateService[key]}`;
  }
  return "";
};
