import { ActionProps } from "models/common";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getListDownloadUserApi } from "services/dowload.service";
import {
  getListDownloadUserFailure,
  setListDownloadUser,
} from "./download.action";
import { DOWNLOAD_ACTION_TYPES } from "./download.types";

export function* fetchListUserDownload({ payload }: ActionProps) {
  try {
    const { data } = yield call(getListDownloadUserApi, payload);

    if (data?.data) {
      yield put(setListDownloadUser(data));
    }
  } catch (error) {
    yield put(getListDownloadUserFailure());
  }
}

export function* getListUserDownloadSaga() {
  yield takeLatest(
    DOWNLOAD_ACTION_TYPES.GET_LIST_DOWNLOAD,
    fetchListUserDownload
  );
}

export function* listDownloadSaga() {
  yield all([call(getListUserDownloadSaga)]);
}
