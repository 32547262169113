import { SYSTEM_CONSTANT } from "./system.constant";
import ja_JP from "antd/lib/locale/ja_JP";

export const LOCALE = {
  JAPANESE: "jp",
  ENGLISH: "en",
};

export const NOTIFICATION_TYPE = {
  SUCCESS: "success",
  INFO: "info",
  WARNING: "warning",
  ERROR: "error",
};

export const LOCAL_STORAGE_KEY = {
  TOKEN: "token",
  CURRENT_USER_TOKEN: "current_user_token",
  EMAIL_FOR_SIGN_IN: "email_for_sign_in",
};

export const ENVIRONMENT = {
  PRODUCTION: "production",
  STAGING: "staging",
  DEVELOPMENT: "development",
};

export const FAVICON_ENVIRONMENT = {
  PRODUCTION: "/logo-production.png",
  STAGING: "/logo-staging.png",
  DEVELOPMENT: "/logo-dev.png",
};

export const DEFAULT_FILTER = {
  page: SYSTEM_CONSTANT.SYSTEM_PAGE_SIZE_DEFAULT_INITIAL_SCREEN,
  pageSize: SYSTEM_CONSTANT.SYSTEM_ROW_PER_PAGE_OPTIONS[1],
};

export const FORM_ITEM_TYPE = {
  INPUT: "input",
  SELECT: "select",
  FILE: "file",
};

export const DEFAULT_LOCATE_SYSTEM = ja_JP;
