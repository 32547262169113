import {
  getAuth,
  getIdToken,
  onAuthStateChanged,
  sendSignInLinkToEmail,
  signInWithEmailLink,
  signOut,
  User,
  UserCredential,
} from "@firebase/auth";
import { initializeApp } from "@firebase/app";
export type UserData = {
  createdAt: Date;
  displayName: string;
  email: string;
};

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  appId: process.env.REACT_APP_ID,
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const firebaseApp = initializeApp(firebaseConfig);

export const auth = getAuth();

export const sendSignInLinkToEmailFirebase = async (
  email: string
): Promise<void> => {
  if (!auth || !email) {
    return;
  }
  const actionCodeSettings = {
    url: "http://localhost:3000",
    handleCodeInApp: true,
  };

  return await sendSignInLinkToEmail(auth, email, actionCodeSettings);
};

export const signInWithEmailLinkFirebase = async (
  email: string
): Promise<UserCredential | undefined> => {
  if (!email) {
    return;
  }
  return await signInWithEmailLink(auth, email, window.location.href);
};

export const signOutUserFirebase = async (): Promise<void> => {
  return await signOut(auth);
};

export const refreshTokenFirebase = async (): Promise<string> => {
  try {
    const currentUser = await getCurrentUser();
    if (currentUser) {
      const token = await getIdToken(currentUser, true);
      return token;
    }
    return "";
  } catch (error) {
    throw new Error(error as string);
  }
};

export const getCurrentUser = (): Promise<User | null> => {
  return new Promise((resolve, reject) => {
    const unsubscribe = onAuthStateChanged(
      auth,
      (userAuth) => {
        unsubscribe();
        resolve(userAuth);
      },
      reject
    );
  });
};
