export const SYSTEM_CONSTANT = {
  SYSTEM_FILTER_SORT_DELAY_TIME: 500,
  SYSTEM_PAGE_SIZE_DEFAULT: 50,
  SYSTEM_PAGE_DEFAULT: 1,
  SYSTEM_ROW_PER_PAGE_OPTIONS: [30, 50],
  SYSTEM_PAGE_SIZE_DEFAULT_INITIAL_SCREEN: 1,
  SYSTEM_RELOAD_PAGE_DELAY_TIME: 1000,
  SYSTEM_RELOAD_PAGE_DELAY_TIME_REDIRECT: 3000,
  SYSTEM_DEFAULT_IN_ACTIVE_TIME: 30, // 30 day
  SYSTEM_NUMBER_REGEX: /^\d+$/,
  SYSTEM_EMAIL_REGEX:
    // eslint-disable-next-line no-useless-escape
    /^\s*(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))\s*$/,
  SYSTEM_WHITE_SPACE_REGEX: /^$|^\S+.*/,
  SYSTEM_VALIDATE_VERSION_REGEX: /^(\d{1,2}\.)(\d{1,2}\.)(\*|\d{1,2})$/,
  SYSTEM_VALIDATE_HH_MM: /^([0-1]?[0-9]|2[0-3])時間[0-5][0-9]分$/,
  SYSTEM_VALIDATE_HH_MM_1: /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$/,
  SYSTEM_VALIDATE_HH_MM_2: /^(0[0-9]|[1-2][0-4]):([0-5]?[0-9])$/,
  SYSTEM_NUMBER_LIMIT_CHARACTER_REGEX: /^[0-9]{0,10}$/,
  SYSTEM_LIMIT_LENGTH_REGEX: /^.{0,10}$/,
  SYSTEM_LIMIT_SERIAL_NUMBER_ALPHABET_LENGTH_REGEX: /^[a-z|A-Z|0-9]{12}$/,
  SYSTEM_LIMIT_SIM_ID_NUMBER_LENGTH_REGEX:
    /^([a-z|A-Z|0-9]{19}|[a-z|A-Z|0-9]{32})$/,
  SYSTEM_LIMIT_IMEI_NUMBER_LENGTH_REGEX: /^[0-9]{15}$/,
  SYSTEM_MAC_ADDRESS_FORMAT_REGEX: /^\w{2}:\w{2}:\w{2}:\w{2}:\w{2}:\w{2}$/,
  SYSTEM_FACTORY_INSPECTION_DATE_FORMAT_REGEX:
    /(20\d{2})(0[1-9]|10|11|12)(0[1-9]|1[0-9]|2[0-9]|30|31)/,
  SYSTEM_DATE_FORMAT_YEAR_MONTH_DAY: "YYYY/M/D",
  SYSTEM_SUCCESS_RESPONSE_DATA: "成功",
  SYSTEM_FAILURE_RESPONSE_DATA: "失敗",
  SYSTEM_HOURS_MINUTES_FORMAT: "HH:mm",
  SYSTEM_MINUTES_SECOND_FORMAT: "mm:ss",
  SYSTEM_HOURS_FORMAT: "HH:00",
  SYSTEM_TIME_PICKER_WIDTH: 505,
  SYSTEM_TIME_PICKER_HEIGHT: 45,
  SYSTEM_INPUT_NUMBER_WIDTH: 505,
  SYSTEM_INPUT_NUMBER_HEIGHT: 45,
  SYSTEM_LINK_WIDTH_COLUMN: 85,
  SYSTEM_TABLE_HORIZONTAL_SCROLL_WIDTH: 1920,
  SYSTEM_MEDIUM_SCREEN_WIDTH: 1640,
  SYSTEM_ALLOW_UPLOAD_BIN_FILE: ".bin",
  SYSTEM_TYPE_ACCEPT_IMAGE: "image/*",
  SYSTEM_LOCAL_DATE_FORMAT: "yyyy-MM-DD",
  SYSTEM_LOCAL_DATE_FORMAT_YYYY_MM_DD: "yyyy/MM/DD",
  SYSTEM_LOCAL_DATE_TIME_FORMAT: "yyyy-MM-DD HH:mm:ss",
  SYSTEM_LOCAL_DATE_TIME_FORMAT_YYYY_MM_DD: "yyyy/MM/DD HH:mm:ss",
  SYSTEM_JAPANESE_FORMAT_DATE: "YYYY年MM月DD日",
  SYSTEM_TITLE_WEARA_APP: "Weara AIR管理画面",
};
