import { AxiosResponse } from "axios";
import { FIRMWARE_ENDPOINT } from "constants/endpoint.constant";
import { get, form, deleteMethod } from "./base.service";

export const getLisFirmware = async (
  page: number,
  pageSize: number
): Promise<AxiosResponse> => {
  return await get(FIRMWARE_ENDPOINT.FIRMWARE, { page, pageSize });
};

export const createFirmware = async (
  firmware: FormData
): Promise<AxiosResponse> => {
  return await form(FIRMWARE_ENDPOINT.FIRMWARE, "POST", firmware);
};

export const getFirmwareDetail = async (id: number): Promise<AxiosResponse> => {
  return await get(`${FIRMWARE_ENDPOINT.FIRMWARE}/${id}`);
};

export const updateFirmware = async (
  id: number,
  firmware: FormData
): Promise<AxiosResponse> => {
  return await form(`${FIRMWARE_ENDPOINT.FIRMWARE}/${id}`, "PUT", firmware);
};

export const deleteFirmware = async (id: number): Promise<AxiosResponse> => {
  return await deleteMethod(`${FIRMWARE_ENDPOINT.FIRMWARE}/${id}`);
};
