import { RESPONSE_STATUS_CODES } from "constants/response.constant";
import { ActionProps, ResponseGenerator } from "models/common";
import { all, call, put, takeEvery } from "redux-saga/effects";
import { getDetailAdminApi, getListAdminApi } from "services/admin.service";
import {
  getDetailAdminFailure,
  getDetailAdminSuccess,
  getListAdminFailure,
  setListAdmin,
} from "./admin.action";
import { ADMIN_ACTION_TYPES } from "./admin.types";

export function* fetchListAdmin({ payload }: ActionProps) {
  try {
    const { data } = yield call(getListAdminApi, payload);
    if (data) {
      yield put(setListAdmin(data));
    }
  } catch (error) {
    yield put(getListAdminFailure(error));
  }
}
export function* fetchAdminDetail({ payload }: ActionProps) {
  try {
    const { data } = yield call(
      getDetailAdminApi,
      payload
    ) as ResponseGenerator;
    if (data.data && data.statusCode === RESPONSE_STATUS_CODES.GET) {
      yield put(getDetailAdminSuccess(data.data));
    }
  } catch (error) {
    yield put(getDetailAdminFailure());
  }
}
export function* getListAdminSaga() {
  yield takeEvery(ADMIN_ACTION_TYPES.GET_LIST_ADMIN, fetchListAdmin);
}

export function* deleteAdminSuccess() {
  yield takeEvery(ADMIN_ACTION_TYPES.DELETE_ADMIN_SUCCESS, fetchListAdmin);
}
export function* getDetailAdminSaga() {
  yield takeEvery(ADMIN_ACTION_TYPES.GET_DETAIL_ADMIN, fetchAdminDetail);
}
export function* adminSaga() {
  yield all([
    call(getListAdminSaga),
    call(deleteAdminSuccess),
    call(getDetailAdminSaga),
  ]);
}
