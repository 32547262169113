import { adminReducer } from "./admin/admin.reducer";
import { combineReducers } from "redux";
import { authReducer } from "./auth/auth.reducer";
import { userDownloadReducer } from "./download/download.reducer";
import { firmwareReducer } from "./firmware/firmware.reducer";
import { wearaReducer } from "./weara/weara.reducer";
import { serviceReducer } from "./service/service.reducer";
import { userReducer } from "./user/user.reducer";

export const rootReducer = combineReducers({
  auth: authReducer,
  admin: adminReducer,
  userDownload: userDownloadReducer,
  weara: wearaReducer,
  firmware: firmwareReducer,
  service: serviceReducer,
  user: userReducer,
});
