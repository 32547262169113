import { BaseButton } from "components/base-button";
import { BaseText } from "components/base-text";
import { ReactElement } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import "./index.scss";

const NotFoundPage = (): ReactElement => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const params = useParams();
  const handleGoBack = (): void => {
    const previousRouter = params["*"]?.split("=")[1];
    if (previousRouter) {
      navigate(previousRouter);
    } else {
      navigate("/");
    }
  };

  return (
    <div className={"not-found"}>
      <BaseText fontSize="fs26" text={t("common.notFound")} />
      <BaseButton text={t("common.goBack")} onClick={handleGoBack} />
    </div>
  );
};

export default NotFoundPage;
