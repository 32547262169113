export enum ModelOptionsEnum {
  Air,
  Ever,
}
export const MODEL_OPTION_NO_SET = null;

export enum OpenStatusOptionsEnum {
  Ready,
  StandBy,
  Active,
  Suspended,
  InActive,
  Terminated,
}
export enum ConnectStatusEnum {
  Offline,
  Online,
}
export enum OpenStatusEnum {
  Off,
  On,
}
export enum ModelFieldEnum {
  Off,
  On,
  NoSet,
}
export const EMAIL_FIELD_EMPTY = "weara.table.field.emailEmpty";
export const CONNECT_STATUS = {
  ONLINE: {
    KEY: ConnectStatusEnum.Online,
    NAME: "weara.table.field.connectStatus.online",
  },
  OFFLINE: {
    KEY: ConnectStatusEnum.Offline,
    NAME: "weara.table.field.connectStatus.offline",
  },
};
export const OPEN_STATUS = {
  ON: {
    KEY: OpenStatusEnum.On,
    NAME: "weara.table.field.openStatus.on",
  },
  OFF: {
    KEY: OpenStatusEnum.Off,
    NAME: "weara.table.field.openStatus.off",
  },
};

export const MODEL_FIELD = {
  ON: {
    KEY: ModelFieldEnum.On,
    NAME: "weara.table.field.model.ever",
  },
  OFF: {
    KEY: ModelFieldEnum.Off,
    NAME: "weara.table.field.model.air",
  },
  NO_SET: {
    KEY: MODEL_OPTION_NO_SET,
    NAME: "weara.table.field.model.noSet",
  },
};

export const MODEL_OPTIONS = [
  { value: ModelOptionsEnum.Air, label: "weara.table.field.model.air" },
  { value: ModelOptionsEnum.Ever, label: "weara.table.field.model.ever" },
];

export const OPEN_STATUS_OPTIONS = [
  {
    value: OpenStatusOptionsEnum.Ready,
    label: "weara.detail.openingStatus.ready",
  },
  {
    value: OpenStatusOptionsEnum.StandBy,
    label: "weara.detail.openingStatus.standby",
  },
  {
    value: OpenStatusOptionsEnum.Active,
    label: "weara.detail.openingStatus.active",
  },
  {
    value: OpenStatusOptionsEnum.Suspended,
    label: "weara.detail.openingStatus.suspended",
  },
  {
    value: OpenStatusOptionsEnum.InActive,
    label: "weara.detail.openingStatus.inActive",
  },
  {
    value: OpenStatusOptionsEnum.Terminated,
    label: "weara.detail.openingStatus.terminated",
  },
];

export const WEARA_ALREADY_EXIST = "P2002";

export const INITIAL_REGISTER_VALUES = {
  serial: "",
  sim_id: "",
  mac_address: "",
  imei: "",
  factory_shipment_date: "",
  keyNumber: "",
};

export const DEFAULT_WEARA_KEY_LENGTH = 4;
