import { ReactElement } from "react";
import "./index.scss";

const Spinner = (): ReactElement => (
  <div className="spinner-overlay">
    <div className="spinner-container">
      <div className="lds-ripple">
        <div></div>
        <div></div>
      </div>
    </div>
  </div>
);

export default Spinner;
