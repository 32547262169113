export const AUTH_ENDPOINT = {
  USER_INFO: "/user/info",
  USER_REGISTER: "/user/register",
  SIGNED_IN: "/user/signed_in",
  SIGNED_OUT: "/user/signed_out",
  LOGIN: "/login",
  GENERATE_TOKEN: "/generate-token",
  SIGN_IN_EMAIL_LINK: "/signin-email-link",
};

export const ADMIN_ENDPOINT = {
  ADMIN: "/admins",
};

export const DOWNLOAD_ENDPOINT = { USER: "/user", DOWNLOAD: "/data/download" };
export const WEARA_ENDPOINT = {
  WEARA_CORE: "/weara-core",
  IMPORT_CSV: "/weara-core/import-csv",
  UPDATE_WEARA: "/weara-core/update",
  REBOOT: "/weara-core/reboot",
  REBOOT_LIST: "/weara-core/reboot-list",
  FACTORY_RESET: "/weara-core/factory-reset",
  FACTORY_RESET_LIST: "/weara-core/factory-reset-list",
  WEARA_CORE_DELETE: "/weara-core/delete",
};

export const FIRMWARE_ENDPOINT = {
  FIRMWARE: "/firmwares",
};

export const SERVICES_ENDPOINT = {
  SERVICES_SETTING: "service-settings",
};
export const USER_ENDPOINT = "/user";
export const ALARM_ENDPOINT = "alarms";
export const SMART_ALARM_ENDPOINT = "smart-alarms";
export const PRESIGNED_URL_ENDPOINT = "/presigned-url";
