import { TypeCommon } from "models/common";
import { AnyAction } from "redux";
import { DOWNLOAD_ACTION_TYPES } from "./download.types";

const INITIAL_STATE = {
  listUserDownload: {
    data: [],
    total: 0,
  },
  error: null,
  isLoading: false,
};

export const userDownloadReducer = (
  state = INITIAL_STATE,
  action: AnyAction
): TypeCommon => {
  const { type, payload } = action;
  switch (type) {
    case DOWNLOAD_ACTION_TYPES.GET_LIST_DOWNLOAD:
      return { ...state, isLoading: true };
    case DOWNLOAD_ACTION_TYPES.GET_LIST_DOWNLOAD_SUCCESS:
      return {
        ...state,
        isLoading: false,
        listUserDownload: payload,
      };
    case DOWNLOAD_ACTION_TYPES.GET_LIST_DOWNLOAD_FAILURE:
      return { ...state, isLoading: false };

    default:
      return { ...state };
  }
};
