export const RESPONSE_STATUS_CODES = {
  GET: 200,
  CREATED: 201,
  ENTITY_ERROR: 422,
  DELETED: 204,
  UPDATED: 200,
  TOKEN_EXPIRED: 419,
  NOT_FOUND: 404,
  BAD_REQUEST: 400,
  CONFLICT: 409,
};

export const ERROR_MESSAGE = {
  UNAUTHORIZED: {
    key: "error.unauthorized",
    message: "Unauthorized",
  },
  BAD_REQUEST: {
    key: "error.bad_request",
    message: "Bad Request",
  },
  VALIDATION_ENTITY_ERROR: {
    key: "error.validate_entity_error",
    message: "Validate entity error",
  },
  USER_ALREADY_EXISTS: {
    key: "error.user_already_exists",
    message: "既に登録済みのアカウントです。",
  },
  USER_NOT_EXISTS: {
    key: "error.user_not_exists",
    message: "ユーザーが存在しません。",
  },
  REGISTER_USER_ERROR: {
    key: "error.register_user_error",
    message: "アカウント作成処理に失敗しました。",
  },
  SAVE_ERROR: {
    key: "error.save_error",
    message: "保存処理に失敗しました。",
  },
  NOT_FOUND: {
    key: "error.not_found",
    message: "Resource Not Found",
  },
  TOKEN_EXPIRED: {
    key: "error.token_expired",
    message: "Token expired",
  },
  USER_CANNOT_DELETE_THEMSElVES: {
    key: "error.user_cannot_delete_themselves",
    message: "ユーザーは自分自身を削除できません。",
  },
  USER_CANNOT_DELETE: {
    key: "error.user_cannot_delete",
    message: "アカウント削除に失敗しました。",
  },
  FILE_TYPE_INVALID: {
    key: "error.file_type_invalid",
    message: "Invalid file type",
  },
  IMPORT_CSV_WEARA_CORE_ERROR: {
    key: "error.import_csv_error",
    message: "weara AIR COREの一括登録に失敗しました。",
  },
  SERVICE_SETTINGS_NOT_EXIST: {
    key: "error.service_settings_not_exist",
    message: "Service settings not exist",
  },
  USER_WAS_DELETED: {
    key: "error.user_was_deleted",
    message: "ユーザーが削除されました。",
  },
  WEARA_CORE_ALREADY_LINKED: {
    key: "error.weara_core_already_linked",
    message: "シリアルが既に他のユーザーに紐づいているため失敗しました。",
  },
  WEARA_CORE_NOT_LINKED: {
    key: "error.weara_core_not_linked",
    message: "Weara Core not linked",
  },
  CANNOT_START_REBOOT: {
    key: "error.cannot_start_reboot",
    message: "ユーザーが削除されました。",
  },
  CANNOT_START_FACTORY_RESET: {
    key: "error.cannot_start_factory_reset",
    message: "ファクトリーリセットの設定に失敗しました。",
  },
  DATA_DOWNLOAD_IN_PROCESSING: {
    key: "error.data_download_in_processing",
    message: "データダウンロード中です。",
  },
  INVALID_DATE_RANGE: {
    key: "error.invalid_date_range",
    message: "Invalid date range, minimum must be 1 day",
  },
  CANNOT_RECOVER_USER: {
    key: "error.cannot_recover_user",
    message: "アカウントの復元に失敗しました。",
  },
  NOT_FOUND_ANY_WEARA_CORE: {
    key: `error.not_found_any_weara_core`,
    message: `Not found any weara core!`,
  },
};
