import React, { ReactElement } from "react";
import "./index.scss";

type Props = {
  text: string;
  color?: string;
  fontSize?: string;
  fontWeight?: string;
  marginLeft?: string;
  marginRight?: string;
  paddingLeft?: string;
  paddingRight?: string;
  paddingTop?: string;
  paddingBottom?: string;
  lineHeight?: string;
  className?: string;
};

const BaseText = ({
  text,
  color,
  fontSize,
  fontWeight,
  marginLeft,
  marginRight,
  paddingLeft,
  paddingRight,
  paddingTop,
  paddingBottom,
  lineHeight,
  className,
}: Props): ReactElement => {
  return (
    <p
      className={`${className} base-text base-text--${color} base-text--${fontSize} base-text--${fontWeight} base-text--${marginLeft} base-text--${marginRight} base-text--${paddingLeft} base-text--${paddingRight} base-text--${paddingBottom} base-text--${paddingTop} base-text--${lineHeight}`}
    >
      {text}
    </p>
  );
};

export { BaseText };
