import { ActionProps, TypeCommon } from "models/common";
import { createAction } from "utils/reducer.utils";
import { WEARA_ACTION_TYPES } from "./weara.types";

// get weara list
export const getListWearaAction = (payload: TypeCommon): ActionProps => {
  return createAction(WEARA_ACTION_TYPES.GET_LIST_WEARA, payload);
};

export const getListWearaSuccessAction = (payload: TypeCommon): ActionProps => {
  return createAction(WEARA_ACTION_TYPES.GET_LIST_WEARA_SUCCESS, payload);
};

export const getListWearaFailureAction = (payload: TypeCommon): ActionProps => {
  return createAction(WEARA_ACTION_TYPES.GET_LIST_WEARA_FAILURE, payload);
};

// get weara detail
export const getWearaDetailAction = (id: string): ActionProps => {
  return createAction(WEARA_ACTION_TYPES.GET_WEARA_DETAIL, { id });
};

export const getWearaDetailSuccessAction = (
  payload: TypeCommon
): ActionProps => {
  return createAction(WEARA_ACTION_TYPES.GET_WEARA_DETAIL_SUCCESS, payload);
};

export const getWearaDetailFailureAction = (
  payload: TypeCommon
): ActionProps => {
  return createAction(WEARA_ACTION_TYPES.GET_WEARA_DETAIL_FAILURE, payload);
};

// register
export const createWearaAction = (payload: TypeCommon): ActionProps => {
  return createAction(WEARA_ACTION_TYPES.CREATE_WEARA, payload);
};

export const createWearaSuccessAction = (payload: TypeCommon): ActionProps => {
  return createAction(WEARA_ACTION_TYPES.CREATE_WEARA_SUCCESS, payload);
};

export const createWearaFailureAction = (payload: TypeCommon): ActionProps => {
  return createAction(WEARA_ACTION_TYPES.CREATE_WEARA_FAILURE, payload);
};

// clear state

export const clearWearaAction = (): ActionProps => {
  return createAction(WEARA_ACTION_TYPES.CLEAR);
};
