import { SYSTEM_CONSTANT } from "constants/system.constant";
import { TypeCommon } from "models/common";
import { UserSmartAlarmResponse } from "models/user";
import { AnyAction } from "redux";
import { getLocalDaysOfWeek } from "utils/user.util";
import { USER_ACTION_TYPES } from "./user.types";

const INITIAL_STATE = {
  user: null,
  users: {
    data: [],
    page: SYSTEM_CONSTANT.SYSTEM_PAGE_SIZE_DEFAULT_INITIAL_SCREEN,
    pageSize: SYSTEM_CONSTANT.SYSTEM_PAGE_SIZE_DEFAULT,
    total: SYSTEM_CONSTANT.SYSTEM_PAGE_SIZE_DEFAULT,
  },
  error: null,
  isLoading: false,
  alarms: undefined,
  alarm: null,
  smartAlarms: undefined,
  smartAlarm: null,
  nickname: null,
  selectedDays: [],
};
const getSmartSelectedDays = (payload: UserSmartAlarmResponse[]): string[] => {
  const days: string[] = [];
  if (payload) {
    for (const smartAlarm of payload) {
      const day = getLocalDaysOfWeek(smartAlarm?.weekdays);
      days.push(...day);
    }
  }
  return days;
};
export const userReducer = (
  state = INITIAL_STATE,
  action: AnyAction
): TypeCommon => {
  const { type, payload } = action;
  switch (type) {
    case USER_ACTION_TYPES.GET_USER_LIST:
      return { ...state, isLoading: true };
    case USER_ACTION_TYPES.GET_USER_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        users: payload,
        user: null,
      };
    case USER_ACTION_TYPES.GET_USER_LIST_FAILURE:
      return { ...state, isLoading: false };

    case USER_ACTION_TYPES.GET_USER_DETAIL:
      return { ...state, isLoading: true };
    case USER_ACTION_TYPES.GET_USER_DETAIL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        user: payload,
        error: null,
        nickname: payload.nickname,
      };
    case USER_ACTION_TYPES.GET_USER_DETAIL_FAILURE:
      return { ...state, isLoading: false, error: payload };

    case USER_ACTION_TYPES.GET_ALARM_LIST:
      return { ...state, isLoading: true };
    case USER_ACTION_TYPES.GET_ALARM_LIST_SUCCESS:
      return { ...state, isLoading: false, alarms: payload };
    case USER_ACTION_TYPES.GET_ALARM_LIST_FAILURE:
      return { ...state, isLoading: false };
    case USER_ACTION_TYPES.GET_ALARM_DETAIL:
      return { ...state, isLoading: true };
    case USER_ACTION_TYPES.GET_ALARM_DETAIL_SUCCESS:
      return { ...state, isLoading: false, alarm: payload };
    case USER_ACTION_TYPES.GET_ALARM_DETAIL_FAILURE:
      return { ...state, isLoading: false, user: null };

    case USER_ACTION_TYPES.GET_SMART_ALARM_LIST:
      return { ...state, isLoading: true };
    case USER_ACTION_TYPES.GET_SMART_ALARM_LIST_SUCCESS:
      return { ...state, isLoading: false, smartAlarms: payload, error: null };
    case USER_ACTION_TYPES.GET_SMART_ALARM_LIST_FAILURE:
      return { ...state, isLoading: false, error: payload };

    case USER_ACTION_TYPES.GET_SMART_ALARM_DETAIL:
      return { ...state, isLoading: true };
    case USER_ACTION_TYPES.GET_SMART_ALARM_DETAIL_SUCCESS:
      return { ...state, isLoading: false, smartAlarm: payload };
    case USER_ACTION_TYPES.GET_SMART_ALARM_DETAIL_FAILURE:
      return { ...state, isLoading: false };

    case USER_ACTION_TYPES.SET_LOADING:
      return { ...state, isLoading: payload };
    case USER_ACTION_TYPES.GET_SELECTED_DAYS:
      return { ...state, selectedDays: getSmartSelectedDays(payload) };
    default:
      return { ...state };
  }
};
