import { AxiosResponse } from "axios";
import { ADMIN_ENDPOINT } from "constants/endpoint.constant";
import { AdminType } from "models/admin";
import { FilterType } from "models/common";
import { deleteMethod, get, post, put } from "./base.service";

const url = ADMIN_ENDPOINT.ADMIN;
export const getListAdminApi = async (
  params: FilterType
): Promise<AxiosResponse> => {
  return await get(url, params);
};
export const getDetailAdminApi = async (id: string): Promise<AxiosResponse> => {
  return await get(`${url}/${id}`);
};
export const updateAdminApi = async (
  body: AdminType
): Promise<AxiosResponse> => {
  return await put(`${url}/${body.id}`, body);
};

export const registerAdminApi = async (
  body: AdminType
): Promise<AxiosResponse> => {
  return await post(`${url}`, body);
};

export const deleteAdminApi = async (id: string): Promise<AxiosResponse> => {
  return await deleteMethod(`${url}/${id}`);
};
