/* eslint-disable react/jsx-props-no-spreading */
import { lazy, ReactElement, Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import Spinner from "components/spinner";
import { ENVIRONMENT, FAVICON_ENVIRONMENT } from "constants/common.constant";
import routes from "routes";
import NotFoundPage from "pages/not-found";
import { SYSTEM_CONSTANT } from "constants/system.constant";
import { ROUTERS } from "constants/routes.constant";
const MainLayout = lazy(() => import("./components/main-layout"));
const Login = lazy(() => import("./pages/login"));
const Admin = lazy(() => import("./pages/admin"));

const App = (): ReactElement => {
  useEffect(() => {
    getFavicon();
    document.title = SYSTEM_CONSTANT.SYSTEM_TITLE_WEARA_APP;
  }, []);

  const getFavicon = (): void => {
    const favicon = document.getElementById("favicon") as HTMLLinkElement;
    switch (process.env.REACT_APP_STAGE) {
      case ENVIRONMENT.PRODUCTION:
        favicon.href = FAVICON_ENVIRONMENT.PRODUCTION;
        break;

      case ENVIRONMENT.STAGING:
        favicon.href = FAVICON_ENVIRONMENT.STAGING;
        break;
      case ENVIRONMENT.DEVELOPMENT:
        favicon.href = FAVICON_ENVIRONMENT.DEVELOPMENT;
        break;
      default:
        break;
    }
  };
  return (
    <Suspense fallback={<Spinner />}>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<Admin />} />
          {routes.map(({ path, component, ...rest }, index) => (
            <Route
              key={`route_${index}`}
              path={path}
              element={component}
              {...rest}
            />
          ))}
        </Route>
        <Route path={ROUTERS.LOGIN} element={<Login />}></Route>
        <Route path="*" element={<NotFoundPage />}></Route>
      </Routes>
    </Suspense>
  );
};

export default App;
