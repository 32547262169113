import { all, call, put, takeLatest } from "redux-saga/effects";
import { FIRMWARE_ACTION_TYPES } from "./firmware.types";
import { getLisFirmware } from "services/firmware.service";
import { ActionProps } from "models/common";
import {
  getListFirmwareFailure,
  getListFirmwareSuccess,
} from "./firmware.action";

export function* fetchListFirmware({
  payload: { page, pageSize },
}: ActionProps) {
  try {
    const { data } = yield call(getLisFirmware, page, pageSize);
    if (data) {
      yield put(getListFirmwareSuccess(data));
    }
  } catch (error) {
    yield put(getListFirmwareFailure(error));
  }
}

export function* onGetListFirmWareStart() {
  yield takeLatest(
    FIRMWARE_ACTION_TYPES.GET_LIST_FIRMWARE_START,
    fetchListFirmware
  );
}

export function* firmwareSaga() {
  yield all([call(onGetListFirmWareStart)]);
}
