import { RESPONSE_STATUS_CODES } from "constants/response.constant";
import { all, call, put, takeLatest } from "redux-saga/effects";
import { getServicesApi } from "services/severvice-settings.service";
import { getServicesFailed, getServicesSuccess } from "./service.action";
import { SERVICE_ACTION_TYPES } from "./service.types";
export function* getServicesSaga() {
  try {
    const { data } = yield call(getServicesApi);
    if (data?.statusCode === RESPONSE_STATUS_CODES.GET) {
      yield put(getServicesSuccess(data.data));
    }
  } catch (error) {
    yield put(getServicesFailed());
  }
}

export function* getServices() {
  yield takeLatest(SERVICE_ACTION_TYPES.GET_REQUEST, getServicesSaga);
}

export function* serviceSaga() {
  yield all([call(getServices)]);
}
