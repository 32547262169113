export const USER_NICK_NAME_MAX_LENGTH = 10;
export const USER_SLEEP_HOUR_LIMIT = {
  MIN: "05:30",
  MAX: "09:00",
};
export const USER_DEFAULT_HEIGHT = {
  MALE: 170,
  FEMALE: 155,
};
export enum Sex {
  Male,
  Female,
}
export const SEX_OPTIONS = [
  {
    value: Sex.Male,
    label: "男性",
  },
  {
    value: Sex.Female,
    label: "女性",
  },
];

export enum AccountType {
  WearaUser,
  WearaAdmin,
  GroupWeara,
  GroupAdmin,
}
export enum SignInType {
  EmailLink,
  Google,
  Twitter,
  Facebook,
  Apple,
}
export const USER_HEIGHT_LIMIT = {
  MIN: 54,
  MAX: 257,
};
export const USER_WEIGHT_LIMIT = {
  MIN: 5,
  MAX: 600,
};

export enum WearingArm {
  NotSet,
  Right,
  Left,
}

export const WEARING_ARM_OPTIONS = [
  {
    value: WearingArm.Left,
    label: "左",
  },
  {
    value: WearingArm.Right,
    label: "右",
  },
];

export const USER_PUBLIC_OPTIONS = [
  {
    value: true,
    label: "公開",
  },
  {
    value: false,
    label: "非公開",
  },
];
export const USER_ON_OFF_OPTIONS = [
  {
    value: true,
    label: "ON",
  },
  {
    value: false,
    label: "OFF",
  },
];
export enum RunningGoalType {
  Time,
  Calories,
  DistanceTraveled,
}

export enum GoalType {
  Calories,
  Steps,
  DistanceTraveled,
}

export const RUNNING_GOAL_TYPE_OPTIONS = [
  {
    value: RunningGoalType.Time,
    label: "時間",
  },
  {
    value: RunningGoalType.Calories,
    label: "カロリー",
  },
  {
    value: RunningGoalType.DistanceTraveled,
    label: "移動距離",
  },
];

export const GOAL_TYPE_OPTIONS = [
  {
    value: GoalType.Calories,
    label: "カロリー",
  },
  {
    value: GoalType.Steps,
    label: "歩数",
  },
  {
    value: GoalType.DistanceTraveled,
    label: "移動距離",
  },
];

export const RUNNING_CALORIE_GOAL_LIMIT = {
  MIN: 25,
  MAX: 1000,
};
export const RUNNING_DISTANCE_GOAL_LIMIT = {
  MIN: 1,
  MAX: 50,
};
export const RUNNING_TIME_GOAL_LIMIT = {
  MIN: 1,
  MAX: 120,
};
export const CATEGORIES_GOAL_LIMIT = {
  MIN: 50,
  MAX: 1000,
};
export const STEP_GOAL_LIMIT = {
  MIN: 3000,
  MAX: 20000,
};
export const DISTANCE_TARGET_LIMIT = {
  MIN: 2,
  MAX: 20,
};
export const HEART_RATE_UPPER_LIMIT = {
  MIN: 120,
  MAX: 200,
};

export const HEART_RATE_LOWER_LIMIT = {
  MIN: 20,
  MAX: 50,
};

export enum UserSOSNoticeType {
  PushNotification,
  Tel,
}

export const SOS_NOTIFICATION_METHOD = [
  {
    value: UserSOSNoticeType.PushNotification,
    label: "アプリプッシュ通知",
  },
  {
    value: UserSOSNoticeType.Tel,
    label: "電話架電",
  },
];

export const CALCULATION_FREQUENCY_LIMIT = {
  MIN: 1,
  MAX: 3600,
};

export const BAROMETRIC_PRESSURE_LIMIT = {
  MIN: 0.005,
  MAX: 3600,
};
export const TOP_TOUCH_LIMIT = {
  MIN: 0,
  MAX: 2147483647,
};

export const ALARM_HOUR_LIMIT = {
  MIN: 0,
  MAX: 23,
};
export const ALARM_MINUTE_LIMIT = {
  MIN: 0,
  MAX: 59,
};
export const USER_SENSOR_3_AXIS_LIMIT = {
  MIN: 0.00125,
  MAX: 3600,
};
export const USER_SENSOR_PPG_LIMIT = {
  MIN: 0.001,
  MAX: 3600,
};
export const USER_SENSOR_THERMISTOR_LIMIT = {
  MIN: 0.001,
  MAX: 3600,
};
export const USER_SENSOR_ATMOSPHERIC_LIMIT = {
  MIN: 0.005,
  MAX: 3600,
};
export const GOAL_WEIGHT_LIMIT = {
  MIN: 5,
  MAX: 165,
};

export const SMART_ALARM_BED_TIME_DEFAULT = 10;
export const SMART_ALARM_BED_WAKE_UP_TIME_DEFAULT = {
  BED_TIME: "23:00",
  WAKE_UP_TIME: "06:00",
};

export const DAY_OF_WEEK_ALARM_OPTIONS = [
  { value: "月", label: "月曜日", disabled: false },
  { value: "火", label: "火曜日", disabled: false },
  { value: "水", label: "水曜日", disabled: false },
  { value: "木", label: "木曜日", disabled: false },
  { value: "金", label: "金曜日", disabled: false },
  { value: "土", label: "土曜日", disabled: false },
  { value: "日", label: "日曜日", disabled: false },
];

export const DAYS_OF_WEEK = ["月", "火", "水", "木", "金", "土", "日"];
export const MONTHS_OF_YEAR = [
  "一月",
  "二月",
  "三月	",
  "四月",
  "五月",
  "六月",
  "七月",
  "八月",
  "九月",
  "十月",
  "十一月",
  "十二月",
];

export const FLOAT_ONE_DECIMAL_DIGIT_REGEX = /^\d*(\.\d{1})?$/;
export const FLOAT_THREE_DECIMAL_DIGIT_REGEX = /^\d*(\.\d{1,3})?$/;
export const CONVERT_KM_TO_M = 1000;
export const CONVERT_KILOGRAM_TO_GRAM = 1000;

export const START_TIME_OPTIONS = [
  { value: "00:00", label: "00:00" },
  { value: "01:00", label: "01:00" },
  { value: "02:00", label: "02:00" },
  { value: "03:00", label: "03:00" },
  { value: "04:00", label: "04:00" },
  { value: "05:00", label: "05:00" },
  { value: "06:00", label: "06:00" },
  { value: "07:00", label: "07:00" },
  { value: "08:00", label: "08:00" },
  { value: "09:00", label: "09:00" },
  { value: "10:00", label: "10:00" },
  { value: "11:00", label: "11:00" },
  { value: "12:00", label: "12:00" },
  { value: "13:00", label: "13:00" },
  { value: "14:00", label: "14:00" },
  { value: "15:00", label: "15:00" },
  { value: "16:00", label: "16:00" },
  { value: "17:00", label: "17:00" },
  { value: "18:00", label: "18:00" },
  { value: "19:00", label: "19:00" },
  { value: "20:00", label: "20:00" },
  { value: "21:00", label: "21:00" },
  { value: "22:00", label: "22:00" },
  { value: "23:00", label: "23:00" },
];
export const END_TIME_OPTIONS = [
  { value: "01:00", label: "01:00" },
  { value: "02:00", label: "02:00" },
  { value: "03:00", label: "03:00" },
  { value: "04:00", label: "04:00" },
  { value: "05:00", label: "05:00" },
  { value: "06:00", label: "06:00" },
  { value: "07:00", label: "07:00" },
  { value: "08:00", label: "08:00" },
  { value: "09:00", label: "09:00" },
  { value: "10:00", label: "10:00" },
  { value: "11:00", label: "11:00" },
  { value: "12:00", label: "12:00" },
  { value: "13:00", label: "13:00" },
  { value: "14:00", label: "14:00" },
  { value: "15:00", label: "15:00" },
  { value: "16:00", label: "16:00" },
  { value: "17:00", label: "17:00" },
  { value: "18:00", label: "18:00" },
  { value: "19:00", label: "19:00" },
  { value: "20:00", label: "20:00" },
  { value: "21:00", label: "21:00" },
  { value: "22:00", label: "22:00" },
  { value: "23:00", label: "23:00" },
  { value: "00:00", label: "00:00" },
];

export const PHONE_NUMBER_REGEX = /^(0|\+81)\d{9,11}$/;
export const NFC_REGEX = /^.{0,144}$/;

export enum Mission {
  Trying,
  Failure,
  Successful,
  Declined,
}
