export const ADMIN_ACTION_TYPES = {
  GET_LIST_ADMIN: "ADMIN/GET_LIST_ADMIN",
  GET_LIST_ADMIN_SUCCESS: "ADMIN/GET_LIST_ADMIN_SUCCESS",
  GET_LIST_ADMIN_FAILURE: "ADMIN/GET_LIST_ADMIN_FAILURE",
  SET_LIST_ADMIN: "ADMIN/SET_LIST_ADMIN",

  GET_DETAIL_ADMIN_SUCCESS: "ADMIN/GET_DETAIL_ADMIN_SUCCESS",
  GET_DETAIL_ADMIN_FAILURE: "ADMIN/GET_DETAIL_ADMIN_FAILURE",
  GET_DETAIL_ADMIN: "ADMIN/GET_DETAIL_ADMIN",

  UPDATE_ADMIN_SUCCESS: "ADMIN/UPDATE_ADMIN_SUCCESS",

  ADMIN_REGISTRATION: "ADMIN/ADMIN_REGISTRATION",
  ADMIN_REGISTRATION_SUCCESS: "ADMIN/ADMIN_REGISTRATION_SUCCESS",
  ADMIN_REGISTRATION_FAILURE: "ADMIN/ADMIN_REGISTRATION_FAILURE",

  DELETE_ADMIN_SUCCESS: "ADMIN/DELETE_ADMIN_SUCCESS",
  DELETE_ADMIN_FAILURE: "ADMIN/DELETE_ADMIN_FAILURE",

  SET_LOADING: "ADMIN/SET_LOADING",
};
