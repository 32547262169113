import { TypeCommon } from "models/common";
import { AnyAction } from "redux";
import { SERVICE_ACTION_TYPES } from "./service.types";

const INITIAL_STATE = {
  data: null,
  isLoading: false,
  error: null,
};

export const serviceReducer = (
  state = INITIAL_STATE,
  action: AnyAction
): TypeCommon => {
  const { type, payload } = action;
  switch (type) {
    case SERVICE_ACTION_TYPES.UPDATE_REQUEST:
      return { ...state, isLoading: true };
    case SERVICE_ACTION_TYPES.UPDATE_SUCCESS:
      return { ...state, isLoading: false, data: payload };
    case SERVICE_ACTION_TYPES.UPDATE_FAILED:
      return { ...state, isLoading: false, error: payload.error };
    case SERVICE_ACTION_TYPES.GET_REQUEST:
      return { ...state, isLoading: true };
    case SERVICE_ACTION_TYPES.GET_SUCCESS:
      return { ...state, isLoading: false, data: payload };
    case SERVICE_ACTION_TYPES.GET_FAILURE:
      return { ...state, isLoading: false };
    case SERVICE_ACTION_TYPES.SET_LOADING:
      return { ...state, isLoading: payload };
    default:
      return state;
  }
};
