export const AUTH_ACTION_TYPES = {
  SET_CURRENT_USER: "AUTH/SET_CURRENT_USER",
  EMAIL_SIGN_IN_START: "AUTH/EMAIL_SIGN_IN_START",
  EMAIL_SIGN_IN_WITH_LINK: "AUTH/EMAIL_SIGN_IN_WITH_LINK",
  SIGN_IN_SUCCESS: "AUTH/SIGN_IN_SUCCESS",
  SIGN_IN_FAILED: "AUTH/SIGN_IN_FAILED",
  SIGN_OUT_START: "AUTH/SIGN_OUT_START",
  SIGN_OUT_SUCCESS: "AUTH/SIGN_OUT_SUCCESS",
  SIGN_OUT_FAILED: "AUTH/SIGN_OUT_FAILED",
  GET_USER_INFO_SUCCESS: "AUTH/GET_USER_INFO_SUCCESS",
  TOKEN_EXPIRED_START: "AUTH/TOKEN_EXPIRED_START",
  AUTH_CLEAR: "AUTH/CLEAR",
  SEND_SIGN_IN_LINK_EMAIL_SUCCESS: "AUTH/SEND_SIGN_IN_LINK_EMAIL_SUCCESS",
  REFRESH_TOKEN_FALSE: "AUTH/REFRESH_TOKEN_FALSE",
};
