import { Button } from "antd";
import { ButtonHTMLType } from "antd/lib/button/button";
import { ReactElement } from "react";
import "./index.scss";

type Props = {
  text: string;
  type?: ButtonHTMLType;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  marginTop?: string;
  marginBottom?: string;
};

const BaseButton = ({
  text,
  onClick,
  className,
  disabled = false,
  type = "button",
  loading = false,
  marginTop,
  marginBottom,
}: Props): ReactElement => {
  return (
    <Button
      htmlType={type}
      disabled={disabled}
      className={`${className} base-button base-button--${marginTop} base-button--${marginBottom}`}
      onClick={onClick}
      loading={loading}
    >
      {text}
    </Button>
  );
};

export { BaseButton };
