import { ROUTERS, SUFFIX_ROUTER } from "constants/routes.constant";
import { RouteProps } from "models/common";
import { lazy } from "react";

const Admin = lazy(() => import("../../src/pages/admin"));
const AdminDetail = lazy(() => import("../../src/pages/admin/detail"));
const Download = lazy(() => import("../../src/pages/download"));
const FW = lazy(() => import("../../src/pages/fw"));
const FwDetail = lazy(() => import("../../src/pages/fw/fw-detail"));
const FwRegister = lazy(() => import("../../src/pages/fw/fw-register"));
const Service = lazy(() => import("../../src/pages/service"));
const User = lazy(() => import("../../src/pages/user"));
const Weara = lazy(() => import("../../src/pages/weara"));
const WearaDetail = lazy(() => import("../../src/pages/weara/weara-detail"));
const WearaRegister = lazy(
  () => import("../../src/pages/weara/weara-register")
);
const WearaCsvRegister = lazy(() => import("../../src/pages/weara/weara-csv"));
const WearaBulkAction = lazy(
  () => import("../../src/pages/weara/weara-bulk-action")
);
const UserDetail = lazy(() => import("../../src/pages/user/detail"));
const AdminRegistration = lazy(() => import("../../src/pages/admin/create"));
const UserAlarm = lazy(() => import("../pages/user/detail/user-alarm/detail"));
const Alarms = lazy(() => import("../pages/user/detail/user-alarm"));
const AlarmRegistration = lazy(
  () => import("../pages/user/detail/user-alarm/create")
);
const SmartAlarmRegistration = lazy(
  () => import("../pages/user/detail/user-smart-alarm/create")
);
const SmartAlarms = lazy(() => import("../pages/user/detail/user-smart-alarm"));
const UserSmartAlarm = lazy(
  () => import("../pages/user/detail/user-smart-alarm/detail")
);

const routes: RouteProps[] = [
  {
    path: ROUTERS.ADMIN,
    component: <Admin />,
  },
  {
    path: `${ROUTERS.ADMIN}/${SUFFIX_ROUTER.DETAIL}`,
    component: <AdminDetail />,
  },
  {
    path: `${ROUTERS.ADMIN}/${SUFFIX_ROUTER.CREATE}`,
    component: <AdminRegistration />,
  },
  {
    path: ROUTERS.DOWNLOAD,
    component: <Download />,
  },
  {
    path: ROUTERS.FW,
    component: <FW />,
  },
  {
    path: ROUTERS.FW_DETAIL,
    component: <FwDetail />,
  },
  {
    path: ROUTERS.FW_REGISTER,
    component: <FwRegister />,
  },
  {
    path: ROUTERS.SERVICE,
    component: <Service />,
  },
  {
    path: ROUTERS.USER,
    component: <User />,
  },
  {
    path: `${ROUTERS.USER}/${SUFFIX_ROUTER.DETAIL}`,
    component: <UserDetail />,
  },

  {
    path: `${ROUTERS.USER}/:userId/${ROUTERS.SMART_ALARM}/${SUFFIX_ROUTER.DETAIL}`,
    component: <UserSmartAlarm />,
  },
  {
    path: `${ROUTERS.USER}/:userId/${ROUTERS.SMART_ALARM}`,
    component: <SmartAlarms />,
  },
  {
    path: `${ROUTERS.USER}/:userId/${ROUTERS.SMART_ALARM}/${SUFFIX_ROUTER.CREATE}`,
    component: <SmartAlarmRegistration />,
  },

  {
    path: `${ROUTERS.USER}/:userId/${ROUTERS.ALARM}/${SUFFIX_ROUTER.DETAIL}`,
    component: <UserAlarm />,
  },
  {
    path: `${ROUTERS.USER}/:userId/${ROUTERS.ALARM}`,
    component: <Alarms />,
  },
  {
    path: `${ROUTERS.USER}/:userId/${ROUTERS.ALARM}/${SUFFIX_ROUTER.CREATE}`,
    component: <AlarmRegistration />,
  },

  {
    path: ROUTERS.WEARA,
    component: <Weara />,
  },
  {
    path: ROUTERS.WEARA_DETAIL,
    component: <WearaDetail />,
  },
  {
    path: ROUTERS.WEARA_REGISTER,
    component: <WearaRegister />,
  },

  {
    path: ROUTERS.WEARA_CSV_REGISTER,
    component: <WearaCsvRegister />,
  },
  {
    path: ROUTERS.WEARA_BULK_ACTION,
    component: <WearaBulkAction />,
  },
];

export default routes;
