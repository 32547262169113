import { AxiosError } from "axios";
import { RESPONSE_STATUS_CODES } from "constants/response.constant";
import { ActionProps } from "models/common";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  getAlarmDetailApi,
  getUserAlarmListApi,
  getUserSmartAlarmDetailApi,
  getUserSmartAlarmListApi,
} from "services/alarm.service";
import { getListUserApi, getUserDetailApi } from "services/user.service";
import {
  getAlarmDetailFailure,
  getAlarmDetailSuccess,
  getAlarmListFailure,
  getAlarmListSuccess,
  getListUserFailure,
  getSmartAlarmDetailFailure,
  getSmartAlarmDetailSuccess,
  getSmartAlarmListFailure,
  getSmartAlarmListSuccess,
  getUserDetailFailure,
  getUserDetailSuccess,
  setListUser,
} from "./user.action";
import { USER_ACTION_TYPES } from "./user.types";

export function* fetchListUser({ payload }: ActionProps) {
  try {
    const { data } = yield call(getListUserApi, payload);
    if (data?.statusCode === RESPONSE_STATUS_CODES.GET) {
      yield put(setListUser(data));
    }
  } catch (error) {
    yield put(getListUserFailure());
  }
}
export function* fetchUserDetail({ payload }: ActionProps) {
  try {
    const { data } = yield call(getUserDetailApi, payload);

    if (data?.statusCode === RESPONSE_STATUS_CODES.GET) {
      yield put(getUserDetailSuccess(data.data));
    }
  } catch (error) {
    yield put(getUserDetailFailure(error as AxiosError));
  }
}

export function* fetchAlarmList({ payload }: ActionProps) {
  try {
    const userId = payload;
    const { data } = yield call(getUserAlarmListApi, userId);
    if (data?.statusCode === RESPONSE_STATUS_CODES.GET) {
      yield put(getAlarmListSuccess(data.data));
    }
  } catch (error) {
    yield put(getAlarmListFailure());
  }
}
export function* fetchSmartAlarmList({ payload }: ActionProps) {
  try {
    const userId = payload;
    const { data } = yield call(getUserSmartAlarmListApi, userId);
    if (data?.statusCode === RESPONSE_STATUS_CODES.GET) {
      yield put(getSmartAlarmListSuccess(data.data));
    }
  } catch (error) {
    yield put(getSmartAlarmListFailure(error));
  }
}
export function* fetchAlarmDetail({ payload }: ActionProps) {
  try {
    const { data } = yield call(getAlarmDetailApi, payload);
    if (data?.statusCode === RESPONSE_STATUS_CODES.GET) {
      yield put(getAlarmDetailSuccess(data.data));
    }
  } catch (error) {
    yield put(getAlarmDetailFailure());
  }
}
export function* fetchSmartAlarmDetail({ payload }: ActionProps) {
  try {
    const { data } = yield call(getUserSmartAlarmDetailApi, payload);
    if (data?.statusCode === RESPONSE_STATUS_CODES.GET) {
      yield put(getSmartAlarmDetailSuccess(data.data));
    }
  } catch (error) {
    yield put(getSmartAlarmDetailFailure());
  }
}
export function* getListAdminSaga() {
  yield takeEvery(USER_ACTION_TYPES.GET_USER_LIST, fetchListUser);
}
export function* getUserDetailSaga() {
  yield takeEvery(USER_ACTION_TYPES.GET_USER_DETAIL, fetchUserDetail);
}
export function* getAlarmList() {
  yield takeEvery(USER_ACTION_TYPES.GET_ALARM_LIST, fetchAlarmList);
}
export function* getAlarmDetail() {
  yield takeEvery(USER_ACTION_TYPES.GET_ALARM_DETAIL, fetchAlarmDetail);
}
export function* getSmartAlarmList() {
  yield takeEvery(USER_ACTION_TYPES.GET_SMART_ALARM_LIST, fetchSmartAlarmList);
}
export function* getSmartAlarmDetail() {
  yield takeEvery(
    USER_ACTION_TYPES.GET_SMART_ALARM_DETAIL,
    fetchSmartAlarmDetail
  );
}
export function* userSaga() {
  yield all([
    call(getListAdminSaga),
    call(getUserDetailSaga),
    call(getAlarmList),
    call(getSmartAlarmList),
    call(getAlarmDetail),
    call(getSmartAlarmDetail),
  ]);
}
