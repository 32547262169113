import { AxiosResponse } from "axios";
import { USER_ENDPOINT } from "constants/endpoint.constant";
import { PaginationType, TypeCommon } from "models/common";
import { get, put } from "./base.service";

const url = USER_ENDPOINT;
export const getListUserApi = async (
  pagination: PaginationType
): Promise<AxiosResponse> => {
  return await get(url, pagination);
};
export const getUserDetailApi = async (id: string): Promise<AxiosResponse> => {
  return await get(`${url}/${id}`);
};

export const updateUserApi = async (
  id: number,
  user: TypeCommon
): Promise<AxiosResponse> => {
  return await put(`${url}/${id}`, user);
};
