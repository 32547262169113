import { AxiosResponse } from "axios";
import { SERVICES_ENDPOINT } from "constants/endpoint.constant";
import { ServicesSettingResponse } from "models/services";
import { get, put } from "./base.service";

const url = SERVICES_ENDPOINT.SERVICES_SETTING;
export const getServicesApi = async (): Promise<AxiosResponse> => {
  return await get(url);
};
export const updateServicesApi = async (
  body: ServicesSettingResponse
): Promise<AxiosResponse> => {
  return await put(url, body);
};
