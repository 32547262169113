import { AxiosError } from "axios";
import { ActionProps, PaginationType, TypeCommon } from "models/common";
import {
  UserAlarmResponse,
  UserDetailResponse,
  UserListResponse,
  UserSmartAlarmResponse,
} from "models/user";
import { createAction } from "utils/reducer.utils";
import { USER_ACTION_TYPES } from "./user.types";

export const getListUserRequest = (payload: PaginationType): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_USER_LIST, payload);
};
export const getListUserFailure = (): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_USER_LIST_FAILURE);
};
export const setListUser = (payload: UserListResponse): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_USER_LIST_SUCCESS, payload);
};

export const getUserDetailSuccess = (
  payload: UserDetailResponse
): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_USER_DETAIL_SUCCESS, payload);
};
export const getUserDetail = (payload: string): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_USER_DETAIL, payload);
};
export const getUserDetailFailure = (error: AxiosError): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_USER_DETAIL_FAILURE, error);
};

export const getAlarmList = (payload: string): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_ALARM_LIST, payload);
};
export const getAlarmListSuccess = (
  payload: UserAlarmResponse[]
): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_ALARM_LIST_SUCCESS, payload);
};
export const getAlarmListFailure = (): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_ALARM_LIST_FAILURE);
};
export const getAlarmDetail = (alarmId: string): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_ALARM_DETAIL, alarmId);
};
export const getAlarmDetailSuccess = (
  payload: UserAlarmResponse
): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_ALARM_DETAIL_SUCCESS, payload);
};
export const getAlarmDetailFailure = (): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_ALARM_DETAIL_FAILURE);
};

export const getSmartAlarmList = (payload: string): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_SMART_ALARM_LIST, payload);
};
export const getSmartAlarmListSuccess = (
  payload: UserSmartAlarmResponse[]
): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_SMART_ALARM_LIST_SUCCESS, payload);
};
export const getSmartAlarmListFailure = (error: TypeCommon): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_SMART_ALARM_LIST_FAILURE, error);
};

export const getSmartAlarmDetail = (payload: string): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_SMART_ALARM_DETAIL, payload);
};
export const getSmartAlarmDetailFailure = (): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_SMART_ALARM_DETAIL_FAILURE);
};
export const getSmartAlarmDetailSuccess = (
  payload: UserSmartAlarmResponse
): ActionProps => {
  return createAction(
    USER_ACTION_TYPES.GET_SMART_ALARM_DETAIL_SUCCESS,
    payload
  );
};
export const setUserLoading = (payload: boolean): ActionProps => {
  return createAction(USER_ACTION_TYPES.SET_LOADING, payload);
};

export const getSelectedDays = (
  payload: UserSmartAlarmResponse[]
): ActionProps => {
  return createAction(USER_ACTION_TYPES.GET_SELECTED_DAYS, payload);
};
