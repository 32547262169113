export const ROUTERS = {
  ADMIN: "/admin",
  USER: "/user",
  DOWNLOAD: "/download",
  FW: "/fw",
  FW_DETAIL: "/fw/:fwId",
  FW_REGISTER: "/fw/register",
  SERVICE: "/service",
  WEARA: "/weara",
  WEARA_DETAIL: "/weara/:wearaId",
  WEARA_REGISTER: "/weara/register",
  WEARA_CSV_REGISTER: "/weara/csv",
  WEARA_BULK_ACTION: "/weara/bulk-action",
  SIGN_OUT: "/sign-out",
  LOGIN: "/login",
  NOT_FOUND: "/not-found",
  SMART_ALARM: "smart-alarms",
  ALARM: "alarms",
};
export const SUFFIX_ROUTER = {
  CREATE: "create",
  DETAIL: ":id",
};
