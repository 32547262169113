import { ActionProps } from "models/common";
import { ServicesSettingResponse } from "models/services";
import { createAction } from "utils/reducer.utils";
import { SERVICE_ACTION_TYPES } from "./service.types";

export const updateSettingsRequest = (): ActionProps => {
  return createAction(SERVICE_ACTION_TYPES.UPDATE_REQUEST);
};

export const updateSettingsSuccess = (
  payload: ServicesSettingResponse
): ActionProps => {
  return createAction(SERVICE_ACTION_TYPES.UPDATE_SUCCESS, payload);
};

export const updateSettingsFailed = (): ActionProps => {
  return createAction(SERVICE_ACTION_TYPES.UPDATE_FAILED);
};

export const getServicesRequest = (): ActionProps => {
  return createAction(SERVICE_ACTION_TYPES.GET_REQUEST);
};

export const getServicesFailed = (): ActionProps => {
  return createAction(SERVICE_ACTION_TYPES.GET_FAILURE);
};

export const getServicesSuccess = (
  payload: ServicesSettingResponse
): ActionProps => {
  return createAction(SERVICE_ACTION_TYPES.GET_SUCCESS, payload);
};
export const setLoadingAction = (payload: boolean): ActionProps => {
  return createAction(SERVICE_ACTION_TYPES.SET_LOADING, payload);
};
