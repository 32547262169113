import { ActionProps, TypeCommon } from "models/common";
import { createAction } from "utils/reducer.utils";
import { FIRMWARE_ACTION_TYPES } from "./firmware.types";

export const getListFirmwareStart = (payload: TypeCommon): ActionProps => {
  return createAction(FIRMWARE_ACTION_TYPES.GET_LIST_FIRMWARE_START, payload);
};

export const getListFirmwareSuccess = (payload: TypeCommon): ActionProps => {
  return createAction(FIRMWARE_ACTION_TYPES.GET_LIST_FIRMWARE_SUCCESS, payload);
};

export const getListFirmwareFailure = (payload: TypeCommon): ActionProps => {
  return createAction(FIRMWARE_ACTION_TYPES.GET_LIST_FIRMWARE_FAILURE, payload);
};
