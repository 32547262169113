import { SYSTEM_CONSTANT } from "constants/system.constant";
import { TypeCommon } from "models/common";
import { AnyAction } from "redux";
import { ADMIN_ACTION_TYPES } from "./admin.types";

const INITIAL_STATE = {
  admin: null,
  admins: {
    data: [],
    pageSize: SYSTEM_CONSTANT.SYSTEM_PAGE_SIZE_DEFAULT,
    page: SYSTEM_CONSTANT.SYSTEM_PAGE_SIZE_DEFAULT_INITIAL_SCREEN,
  },
  error: null,
  isLoading: false,
};

export const adminReducer = (
  state = INITIAL_STATE,
  action: AnyAction
): TypeCommon => {
  const { type, payload } = action;
  switch (type) {
    case ADMIN_ACTION_TYPES.SET_LIST_ADMIN:
      return {
        ...state,
        admins: payload,
        isLoading: false,
      };
    case ADMIN_ACTION_TYPES.GET_LIST_ADMIN:
      return { ...state, isLoading: true };
    case ADMIN_ACTION_TYPES.GET_LIST_ADMIN_FAILURE:
      return {
        ...state,
        error: payload,
        isLoading: false,
        data: [],
      };

    case ADMIN_ACTION_TYPES.GET_DETAIL_ADMIN_SUCCESS:
      return {
        ...state,
        admin: payload,
        isLoading: false,
      };
    case ADMIN_ACTION_TYPES.GET_DETAIL_ADMIN_FAILURE:
      return {
        ...state,
        admin: null,
        isLoading: false,
      };
    case ADMIN_ACTION_TYPES.GET_DETAIL_ADMIN:
      return { ...state, isLoading: true };

    case ADMIN_ACTION_TYPES.UPDATE_ADMIN_SUCCESS:
      return {
        ...state,
        admin: payload,
        isLoading: false,
      };

    case ADMIN_ACTION_TYPES.ADMIN_REGISTRATION_SUCCESS:
      return { ...state, error: null, isLoading: false };

    case ADMIN_ACTION_TYPES.DELETE_ADMIN_SUCCESS:
      return { ...state, error: null, isLoading: false };
    case ADMIN_ACTION_TYPES.DELETE_ADMIN_FAILURE:
      return { ...state, isLoading: false };

    case ADMIN_ACTION_TYPES.SET_LOADING:
      return { ...state, isLoading: payload };
    default:
      return state;
  }
};
