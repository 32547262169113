export const FIRMWARE_ACTION_TYPES = {
  GET_LIST_FIRMWARE_START: "FIRMWARE/GET_LIST_FIRMWARE_START",
  GET_LIST_FIRMWARE_SUCCESS: "FIRMWARE/GET_LIST_FIRMWARE_SUCCESS",
  GET_LIST_FIRMWARE_FAILURE: "FIRMWARE/GET_LIST_FIRMWARE_FAILURE",

  GET_DETAIL_FIRMWARE_START: "FIRMWARE/GET_DETAIL_FIRMWARE_START",
  GET_DETAIL_FIRMWARE_SUCCESS: "FIRMWARE/GET_DETAIL_FIRMWARE_SUCCESS",
  GET_DETAIL_FIRMWARE_FAILURE: "FIRMWARE/GET_DETAIL_FIRMWARE",

  UPDATE_FIRMWARE_START: "FIRMWARE/UPDATE_FIRMWARE_START",
  UPDATE_FIRMWARE_SUCCESS: "FIRMWARE/UPDATE_FIRMWARE_SUCCESS",
  UPDATE_FIRMWARE_FAILURE: "FIRMWARE/UPDATE_FIRMWARE_FAILURE",

  FIRMWARE_REGISTRATION_START: "FIRMWARE/FIRMWARE_REGISTRATION_START",
  FIRMWARE_REGISTRATION_SUCCESS: "FIRMWARE/FIRMWARE_REGISTRATION_SUCCESS",
  FIRMWARE_REGISTRATION_FAILURE: "FIRMWARE/FIRMWARE_REGISTRATION_FAILURE",

  DELETE_FIRMWARE_START: "FIRMWARE/DELETE_FIRMWARE_START",
  DELETE_FIRMWARE_SUCCESS: "FIRMWARE/DELETE_FIRMWARE_SUCCESS",
  DELETE_FIRMWARE_FAILURE: "FIRMWARE/DELETE_FIRMWARE_FAILURE",
};
