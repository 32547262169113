import { ActionProps, FilterType } from "models/common";
import { ListUserDownloadType } from "models/download";
import { createAction } from "utils/reducer.utils";
import { DOWNLOAD_ACTION_TYPES } from "./download.types";

export const getListDownloadUserRequest = (filter: FilterType): ActionProps => {
  return createAction(DOWNLOAD_ACTION_TYPES.GET_LIST_DOWNLOAD, filter);
};
export const getListDownloadUserFailure = (): ActionProps => {
  return createAction(DOWNLOAD_ACTION_TYPES.GET_LIST_DOWNLOAD_FAILURE);
};
export const setListDownloadUser = (
  payload: ListUserDownloadType
): ActionProps => {
  return createAction(DOWNLOAD_ACTION_TYPES.GET_LIST_DOWNLOAD_SUCCESS, payload);
};
