import { ActionProps } from "models/common";
import { all, call, put, takeEvery } from "redux-saga/effects";
import {
  createWearaApi,
  getDetailWearaApi,
  getListWearaApi,
} from "services/weara.service";
import {
  createWearaFailureAction,
  createWearaSuccessAction,
  getListWearaFailureAction,
  getListWearaSuccessAction,
  getWearaDetailFailureAction,
  getWearaDetailSuccessAction,
} from "./weara.actions";
import { WEARA_ACTION_TYPES } from "./weara.types";

export function* fetchListWeara({ payload: { page, pageSize } }: ActionProps) {
  try {
    const { data } = yield call(getListWearaApi, page, pageSize);
    if (data) {
      yield put(getListWearaSuccessAction(data));
    }
  } catch (error) {
    yield put(getListWearaFailureAction(error));
  }
}

export function* fetchDetailWeara({ payload: { id } }: ActionProps) {
  try {
    const { data } = yield call(getDetailWearaApi, String(id));
    if (data) {
      yield put(getWearaDetailSuccessAction(data.data));
    }
  } catch (error) {
    yield put(getWearaDetailFailureAction(error));
  }
}

export function* createWeara({ payload }: ActionProps) {
  try {
    const { data } = yield call(createWearaApi, payload);
    if (data) {
      yield put(createWearaSuccessAction(data.data));
    }
  } catch (error) {
    yield put(createWearaFailureAction(error));
  }
}

export function* fetchListWearaAction() {
  yield takeEvery(WEARA_ACTION_TYPES.GET_LIST_WEARA, fetchListWeara);
}

export function* fetchDetailWearaAction() {
  yield takeEvery(WEARA_ACTION_TYPES.GET_WEARA_DETAIL, fetchDetailWeara);
}

export function* createWearaAction() {
  yield takeEvery(WEARA_ACTION_TYPES.CREATE_WEARA, createWeara);
}

export function* wearaSaga() {
  yield all([
    call(fetchListWearaAction),
    call(fetchDetailWearaAction),
    call(createWearaAction),
  ]);
}
